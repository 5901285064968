import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as colors from '../Styles/Colors'
import SideOptions from './SideOptions'
import NavigationBar from './NavigationBar/NavigationBar'
import { gret_icon } from '../Media/Media'

const CreateLandingPage = () => {
    const [token, setToken] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {

        if (localStorage.getItem('token') !== null) {
            setToken(localStorage.getItem('token'))
        }
        else {
            setToken(null)
        }
    })
    return (
        <div style={{ height: '100vh', color: 'white' }}>
            {token && <SideOptions props={'profile'} />}
            <NavigationBar />
            <div className='w3-row black-bg ' >
                <div className='w3-half center-item landing-section'>
                    <h1 style={{ fontSize: 42 }}><b>Engage with Gamification at Websites and Mobile Apps</b></h1>
                    <p>Elevate your gamification experience with our comprehensive cloud hosting and maintenance solutions. Trusted by industry leaders, we ensure seamless operation and continuous improvement, so you can focus on engaging your audience and driving exceptional results. Let us handle the technicalities while you maximize the impact of your gamification program.</p>
                    <br />
                    <div className='w3-col' style={{ display: 'flex', gap: 16, justifyContent: 'left' }}>
                        <button className='button-dark' onClick={()=>navigate('/signup')}>Join now</button>
                        <button className='button-light-border' onClick={()=>navigate('/about')}>Learn more</button>
                    </div>
                </div>
                <div className='w3-half'>
                    <div className='w3-padding'>
                        <img src={gret_icon} style={{ height: '85vh' }} className='w3-col' />
                    </div>
                </div>
            </div>
            {/* <div className='w3-row buttonPositionVR' >
                <button className="signup_button create_button glow-on-hover" style={{ backgroundColor: 'inherit', width: '100%' }} onClick={() => { window.open('https://buildvr.gretxp.com/', '_blank') }} ><h2><b>VR Experiences</b></h2></button>
            </div>
            <div className='w3-row buttonPositionVR2' >
                <button className="signup_button create_button glow-on-hover" style={{ backgroundColor: 'inherit', width: '100%' }} onClick={() => { navigate('/signup') }} ><h2><b>Gamification</b></h2></button>
            </div> */}
        </div>
    )
}

export default CreateLandingPage
