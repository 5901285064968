export const LandingImage = 'https://storage.googleapis.com/grubox-store/gret/gret_icons/1.png'
export const faces = 'https://storage.googleapis.com/grubox-store/gret/gret_icons/faces.png'
export const user_icon = "https://storage.googleapis.com/grubox-store/gret/img/profile.png";
export const new_reg_icon = "https://storage.googleapis.com/grubox-store/gret/img/coupon.png";
export const quest_icon = "https://storage.googleapis.com/grubox-store/gret/img/revenue.png";
export const images_icon = "https://storage.googleapis.com/grubox-store/gret/img/Reload_icon.png";
export const social_share_icon = "https://storage.googleapis.com/grubox-store/gret/img/Share_icon.png";
export const coupon_icon = "https://storage.googleapis.com/grubox-store/gret/img/Coupon_icon.png";
export const quest_claimed_icon = "https://storage.googleapis.com/grubox-store/gret/img/Gret_icon.png";
export const my_account_icon = "https://storage.googleapis.com/grubox-store/gret/img/Profile_icon.png";
export const meter_icon = "https://storage.googleapis.com/grubox-store/gret/img/Meter_icon.png";
export const logout_icon = "https://storage.googleapis.com/grubox-store/gret/img/Switch_icon.png";
export const intro_image_1 = "https://storage.googleapis.com/grubox-store/gret/img/intro1.png";
export const intro_image_2 = "https://storage.googleapis.com/grubox-store/gret/img/intro2.png";
export const intro_image_3 = "https://storage.googleapis.com/grubox-store/gret/img/intro3.png";
export const intro_image_4 = "https://storage.googleapis.com/grubox-store/gret/img/intro4.jpg";
export const intro_image_5 = ("https://storage.googleapis.com/grubox-store/gret/img/intro5.mp4");
export const activate_image= "https://media-private.canva.com/ze4M4/MAEJQKze4M4/1/s.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJWF6QO3UH4PAAJ6Q%2F20220424%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220424T125142Z&X-Amz-Expires=90374&X-Amz-Signature=d741d8ecb98ec62e410caf979141725af767802fc9459a7b95ff71ae90edd52f&X-Amz-SignedHeaders=host&response-expires=Mon%2C%2025%20Apr%202022%2013%3A57%3A56%20GMT";
export const create_video="https://storage.googleapis.com/grubox-store/gret/assets/video.mp4"
export const gret_logo = "https://storage.googleapis.com/grubox-store/gret/avatar/Icons (12)/Icons_12.svg"
export const login_image = "https://storage.googleapis.com/grubox-store/gret/avatar/Icons (16)/Icons_16.svg"
export const contact_image = "https://storage.googleapis.com/grubox-store/gret/avatar/Icons (17)/Icons_17.svg"
export const gret_icon = "https://storage.googleapis.com/grubox-store/gret/avatar/Icons (15)/Icons_15.svg"
export const create_video_new="https://storage.googleapis.com/grubox-store/gret/assets/Copy%20of%20Main%20Video%20Gretxp%20Platform%20.mp4"
export const GoogleIcon = "https://storage.googleapis.com/grubox-store/gret/local/avatar/Image/GoogleIcon.webp"
export const Uploads = "https://storage.googleapis.com/grubox-store/gret/avatar/Icons (17)/Icons_17.svg"
export const Comments = "https://storage.googleapis.com/grubox-store/gret/avatar/66/66.svg"
export const Coupons = "https://storage.googleapis.com/grubox-store/gret/avatar/65/65.svg"

