import React from 'react';
import './ContactForm.css'
import { contact_image } from '../../Media/Media';
import NavigationBar from '../NavigationBar/NavigationBar';
// No need to import ContactForm.css if you're using W3.CSS for all styles

const ContactForm = () => {
  return (
    <div className="w3-display-container black-bg" style={{ minHeight: '100vh' }}>
      <NavigationBar />


      {/* Right half for the form, using custom styles for fonts and colors */}
      <div className="w3-half landing-section center-item">
        <div className="w3-container">
          <h1 style={{ fontSize: 42 }}><b>Get in touch to step up User Engagement</b></h1>
          <br />
          <h3>Congrats! You are making great progress. One more step to go to the next level.</h3>
          <form className='w3-row'>
            <label className='w3-row'>E-mail
              <input className="outline-input w3-col" type="email" placeholder="E-mail" />
            </label>
            <br/>
            <label className='w3-row'>Message
              <textarea className="outline-input w3-col" placeholder="Message"></textarea>
            </label>
            <button className="button-dark w3-margin-top" style={{ backgroundColor: 'var(--color-2)' }} type="submit">Login</button>
          </form>
          <br />
          <h3>Find the right online software solutions for </h3>
          <div className="center-item-row" style={{ gap: 10, justifyContent: 'left' }}>
            <a className="w3-button w3-border w3-round-xxlarge custom-text-color" style={{ borderColor: 'var(--color-3)' }} href="/3d-design">3D Design</a>
            <a className="w3-button w3-border w3-round-xxlarge custom-text-color" style={{ borderColor: 'var(--color-3)' }} href="/gamification">Gamification</a>
            <a className="w3-button w3-border w3-round-xxlarge custom-text-color" style={{ borderColor: 'var(--color-3)' }} href="/operations-management">Operations Management</a>
          </div>
        </div>
      </div>
      {/* Left half for the image */}

      <div className="w3-half center-item">
        <img
          loading='lazy'
          src={contact_image}
          className='w3-col l8 s10'
          style={{ height: '85vh' }}
          alt="Gamification Gretxp"
        />
      </div>
    </div>
  );
};

export default ContactForm;