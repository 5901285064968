import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { get, logoutClear, set } from '../../LocalStorage/LocalStorage'
import './Create.css'
import * as Media from '../../Media/Media'

const NavigationBar = () => {
    const navigate = useNavigate()
    const [showNavbar, setShowNavbar] = useState(false)
    const [token, setToken] = useState(localStorage.getItem('token'))
    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar)
    }
    function Logout() {
        localStorage.clear();
        set('token', null)
        set("is_superuser", null)
        set("experience_count", null)
        logoutClear()
        setToken(null)
        navigate('/')
    }
    return (
        <div>
            <nav className="navbar">
                <div className="container-nav">
                    <div className="logo-gret">
                        <img alt='BuildVR Gretxp' loading="lazy" src={Media.gret_logo} onClick={() => navigate('/')} className='img-nav' style={{ objectFit: 'scale-down' }} />
                    </div>
                    <div className="menu-icon" onClick={handleShowNavbar}>
                        <i className="fa fa-bars" style={{ fontSize: 25, color: 'white' }} ></i>
                    </div>
                    <div className={`nav-elements  ${showNavbar && 'active'}`}>
                        <ul>
                            <li>
                                <Link className='effect-underline' to="/" >Home</Link>
                            </li>
                            <li>
                                <Link className='effect-underline' to="/pricing">Pricing</Link>
                            </li>
                            <li>
                                <Link className='effect-underline' to="/about">About</Link>
                            </li>
                            <li>
                                <Link className='effect-underline' to="/contact">Contact</Link>
                            </li>
                            <li>
                                {token ?
                                    <button className='button-dark' style={{ padding: '8px 24px' }} onClick={Logout}>LOG OUT</button >
                                    :
                                    <Link to="/login">
                                        <button className='button-dark' style={{ padding: '8px 24px' }}>LOG IN</button >
                                    </Link>
                                }
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default NavigationBar
