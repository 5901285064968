export const get_google_info = async (token) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json'},
        params: { }
    };
    var url = `https://www.googleapis.com/oauth2/v3/tokeninfo?access_token=${token.access_token}`
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    return data
}