import environment from "../Environments/Environmnet";
export const get_dashboard_kpis= async ()=>{ 

  const token = 'Token '+localStorage.getItem('token')
 
  const requestOptions = {
       method: 'GET',
       headers: { 'Content-Type': 'application/json', Authorization: token },
       params: { }
   };
  
   var url=`${environment.server_root}/api/avatar/get_dashboard_kpis/`
   const response = await fetch(url, requestOptions);
   const data = await response.json();
   return data;         
};