export const Fonts = [
    { value: 'Lato', label: 'Lato' },
    { value: 'Abhaya Libre', label: 'Abhaya Libre' },
    { value: 'Merriweather', label: 'Merriweather' },
    { value: 'Alegreya', label: 'Alegreya' },
    { value: 'Montserrat', label: 'Montserrat' },
    { value: 'Aleo', label: 'Aleo' },
    { value: 'Muli', label: 'Muli' },
    { value: 'Arapey', label: 'Arapey' },
    { value: 'Nunito', label: 'Nunito' },
    { value: 'Asap Condensed', label: 'Asap Condensed' },
    { value: 'Assistant', label: 'Assistant' },
    { value: 'Open Sans', label: 'Open Sans' },
    { value: 'Barlow', label: 'Barlow' },
    { value: 'Oswald', label: 'Oswald' },
    { value: 'Bitter', label: 'Bitter' },
    { value: 'Poppins', label: 'Poppins' },
    { value: 'Brawler', label: 'Brawler' },
    { value: 'Roboto', label: 'Roboto' },
    { value: 'Caladea', label: 'Caladea' },
    { value: 'Rokkitt', label: 'Rokkitt' },
    { value: 'Carme', label: 'Carme' },
    { value: 'Rubik', label: 'Rubik' },
    { value: 'Raleway', label: 'Raleway' },
    { value: 'Helvetica', label: 'Helvetica' },
    { value: 'Quicksand', label: 'Quicksand' },
    { value: 'Pacifico', label: 'Pacifico' },
    { value: 'Abril Fatface', label: 'Abril Fatface' },
    { value: 'Dosis', label: 'Dosis' },
    { value: 'VT323', label: 'VT323' },
  ];