import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as colors from '../Styles/Colors';
import '../Styles/global.css'
import '../Styles/media_queries.css'

const SideOptions = (props) => {
    const [showOptions, setShowOptions] = useState(false);

    const navigate=useNavigate();
    useEffect(()=>{
        document.addEventListener('mouseup', function(event){
          var box = document.getElementById('options');
          if(event!==null || event!==undefined){
            if (event.target.id != 'options' ){
                setShowOptions(false)
            }
          }       
        });   
      })
  return (
    <div id='options'>
    {
        showOptions?
        <div className='option_div' id='options' >
            <button id='options' className="option_button" onClick={()=>{navigate('/design')}} style={props.props==='design'?{backgroundColor:'#ccc'}:{}} title="Design Page" >
                <img id='options' className="icons_style" style={props.props==='design'?{width:'80%'}:{}}  src={"./Media/24.png"} />
            </button>
            <button id='options' className="option_button" onClick={()=>{navigate('/quest')}} style={props.props==='quest'?{backgroundColor:'#ccc'}:{}} title="Quest Page">
                <img id='options' className="icons_style" style={props.props==='quest'?{width:'80%'}:{}} src={"./Media/23.png"} />
            </button>
            <button id='options' className="option_button" onClick={()=>{navigate('/profile')}} style={props.props==='profile'?{backgroundColor:'#ccc'}:{}} title="Profile Page">
                <img id='options' className="icons_style" style={props.props==='profile'?{width:'80%'}:{}}  src={"./Media/29.png"} />
            </button>
            <button id='options' className="option_button" onClick={()=>{navigate('/dashboard')}} style={props.props==='dashboard'?{backgroundColor:'#ccc'}:{}} title="Dashboard Page">
                <img id='options' className="icons_style" style={props.props==='dashboard'?{width:'80%'}:{}}  src={"./Media/30.png"} />
            </button>       
            <button id='options' className="option_button" onClick={()=>{navigate('/')}} style={props.props==='create'?{backgroundColor:'#ccc'}:{}} title="Create your own 3D experience">
                <img id='options' className="icons_style" style={props.props==='create'?{width:'80%'}:{}}   src={"./Media/33.png"} />
            </button> 
            <button id='options' className="option_button" onClick={()=>{navigate('/gamification')}} style={props.props==='game3d'?{backgroundColor:'#ccc'}:{}} title="About">
                <img id='options' className="icons_style" style={props.props==='game3d'?{width:'80%'}:{}}   src={"./Media/32.png"} />
            </button>          
            <button id='options' className="option_button" onClick={()=>{localStorage.clear(); navigate('/')}} style={props.props==='logout'?{backgroundColor:'#ccc'}:{}} title="Logout">
                <img id='options' className="icons_style" style={props.props==='logout'?{width:'50%'}:{}} src={"./Media/31.png"} />
            </button>
        </div>
        :
        <button id='options' className="icon_button" onClick={()=>setShowOptions(true)}  title="Click to open more options"><img className="icons_style" style={{width:'50%'}} src={"./Media/22.png"} title="Click to open more options"/></button>
    }    
    </div>
  )
}

export default SideOptions
