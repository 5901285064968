import React from 'react'
import * as colors from '../../Styles/Colors';
import '../../Styles/global.css'
import '../../Styles/Planets.css'
import BackgroundSceneWelcome from './BackgroundSceneWelcome';


const WelcomePage = () => {
  return (
    <div>
        <div className='w3-container w3-center ' style={{position:"absolute",color:colors.off_white_1,width:'100%'}}>
          <h1 style={{margin:0}}>Welcome in!</h1>
          <p style={{margin:0}}>Open a portal of your choice</p>   
        </div>
      <BackgroundSceneWelcome/>
    </div>
  )
}

export default WelcomePage
