import React, { useEffect, useState } from 'react'
import Select from 'react-select';

const FontInput = ({ defaultValue, keyValue, onChange, options }) => {
    const [selected, setSelected] = useState({ value: defaultValue, label: defaultValue })
    const customStyles = {
        option: provided => ({
            ...provided,
            color: 'black'
        }),
        control: provided => ({
            ...provided,
            color: 'black'
        }),
        singleValue: provided => ({
            ...provided,
            color: 'black'
        })
    }
    useEffect(() => {
        if (selected) {
            onChange(keyValue, selected.value)
        }
    }, [selected])

    useEffect(() => {
        setSelected({ value: defaultValue, label: defaultValue })
    }, [defaultValue])

    return (
        <>
            <Select
                defaultValue={selected}
                value={selected}
                onChange={setSelected}
                options={options}
                styles={customStyles}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 8,
                })}
            />
        </>
    )
}

export default FontInput
