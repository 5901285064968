import React, { useState,useEffect } from 'react'
import { updateClientDetails } from '../Services/ChallengesServices';
import * as colors from '../Styles/Colors';
import '../Styles/global.css';
import {get_client_profile} from '../Services/ClientServices'
import Modal from 'react-modal';
import { Oval } from  'react-loader-spinner'
import { set } from '../LocalStorage/LocalStorage';
import { useNavigate } from 'react-router-dom';
import * as media from '../Media/Media'
import SideOptions from './SideOptions';
import LoadingPage from './LoadingPage';


const ClientProfile = () => {

    const [cleintDetails,SetCleintDetails]=useState()
    const [cleintInfo,SetCleintInfo]=useState()
    const [copySuccess,SetCopySuccess]=useState(false)
    const [instance_id,setInstance_id]=useState()
    const [iframe,SetIframe]=useState("<iframe src=\"https://game.gretxp.com?details=" + instance_id + "\" style=\"position: fixed; width: 45%; height: 100%; top: 0; right: 0; bottom: 0; background-color: rgba(0,0,0,0); border-width: 0px;\"></iframe>")
    const [loadingModal, setLoadingModal] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');

    const navigate=useNavigate();
    function closeLoadingModal(event) {
        event.preventDefault()
        setLoadingModal(false);
      }

    const handleIframeChange=(event)=>{
        SetIframe(event.target.value)
    }
    const handleClientDetailsChange=(event)=>{
        const { name, value } = event.target;
        SetCleintDetails({
        ...cleintDetails,
        [name]: value,
        });
    }
    const copyText=(event)=>{
        event.preventDefault();
        navigator.clipboard.writeText(iframe)
        SetCopySuccess(true);
      }
    const UpdateDetails=(event)=>{
        event.preventDefault();
        setLoadingModal(true)
        setLoadingMessage('Saving Content...')
        updateClientDetails(cleintDetails).then(result=>{
           if(result.success===true){
            setLoadingModal(false)
            setLoadingMessage('')
           }
            
        })
    }
    

    useEffect(()=>{
        setLoadingModal(true)
        setLoadingMessage('Loading Content...')
        get_client_profile().then(result=>{
            if(result.success===true){
                SetCleintInfo(result.data)
                let client_instance_id=result.data.client_unique_id
                set('client_unique_id', client_instance_id);
                setInstance_id(client_instance_id)
                let clientIframe="<iframe src=\"https://game.gretxp.com?details=" + client_instance_id + "\" style=\"position: fixed; width: 45%; height: 100%; top: 0; right: 0; bottom: 0; background-color: rgba(0,0,0,0); border-width: 0px;\"></iframe>"
                SetIframe(clientIframe)
                setLoadingModal(false)
                setLoadingMessage('')
            }
            else{
                setLoadingModal(true)
                setLoadingMessage(result.error)
              }
        })
    },[])
   
  return (
    <div style={{color:colors.dark_blue_2,backgroundColor:"white",height:'100vh',marginTop:'-1%'}}>
        <SideOptions props={'profile'}/>   
          
        <div>
            <h1 className="w3-center" style={{fontWeight:'bold'}} >Your Profile</h1>
        </div>
        {
        cleintInfo ?
         <div>
            <div className='w3-row' style={{marginLeft:'10%',width:'80%'}} >
            <div className='w3-third' style={{padding:'2% 5% 0% 0%'}}>
                <label>
                    Brand Name<br></br>
                    <input value={cleintInfo.name} className='signup_input w3-col s12'type="text" onChange={handleClientDetailsChange} name="name" />
                </label>
            </div>
            <div className='w3-third' style={{padding:'2% 5% 0% 0%'}}>
                <label>
                    Website:<br></br>
                    <input value={cleintInfo.website} className='signup_input w3-col s12'type="text" onChange={handleClientDetailsChange} name="website" />
                </label>
            </div>
            <div className='w3-third' style={{padding:'2% 5% 0% 0%'}}>
                <label>
                Purpose of Pop up :<br></br>
                <select value={cleintInfo.target?cleintInfo.target:"Increase sales at Website"} onChange={handleClientDetailsChange} name="target" className='signup_input w3-col s12' >
                    <option value="Increase sales at Website">Increase sales at Website</option>
                    <option value="Increase registered subscribers">Increase registered subscribers</option>
                    <option value="Increase user engagement with your website">Increase user engagement with your website</option>
                </select>
                </label>
            </div>
            
        </div>
        <div className='w3-row' style={{paddingTop:'2%'}}>
            <div className='w3-col l5 s3'><h1></h1></div>
            <div className='w3-col l7 s6'>
                <button className='signup_button w3-col l3 s12' style={{marginTop: '2%'}} onClick={UpdateDetails}>
                    Save
                </button> 
            </div>
        </div>
        

        <div className='w3-row' style={{marginLeft:'10%'}}>
            <h2 style={{fontWeight:'bold'}}> Setup Step 3</h2>
            <p style={{margin:'0%'}}>Copy & Paste this code in the body your website's HTML</p>
            <p style={{margin:'0%'}}> (Recommended position center/right-aligned in a position with no click activity or banner)</p>
            <div className='w3-row'>
            <img src={"https://storage.googleapis.com/grubox-store/gret/local/avatar/doggy/clientHelp.webp"} className='w3-col l4 s8' />
            <div className='w3-col l9 s8'><h1></h1></div>
            
            </div>
            
            <a className='link_button' style={{fontWeight:'bold'}} onClick={()=>window.open("https://www.youtube.com/channel/UCKoIV7_6QJ7dJ79Gvhe223A","blank")}>
                Still need help? Check a this video
            </a>
            <div className='w3-row'>
                
                <textarea value={iframe} onChange={handleIframeChange} className='signup_input w3-col l10 s11' type="text" name="name" /> 
                <div className='w3-col l2 s1'><br></br></div>             
            </div>
            <div className='w3-row' style={{marginTop:'2%'}}>
                <div className='w3-col l3 s0'><h1></h1></div>
                <button className='signup_button w3-col l2 s5' onClick={copyText}>
                    {copySuccess?'Copied':'Copy Code'}
                </button>
                <div className='w3-col l1 s1'><h1></h1></div>
                <button className='signup_button w3-col l2 s5' onClick={()=>navigate('/dashboard')}>
                    View Dasboard
                </button>
            </div>            
        </div>

         </div>:
         <div>
             <button className='signup_button' style={{marginLeft: '44%',marginTop: '5%'}} onClick={UpdateDetails}>
                Save
            </button> 

        </div>
        }
         
        <LoadingPage loadingMessage={loadingMessage} loadingModal={loadingModal}/>

    </div>
  )
}

export default ClientProfile
