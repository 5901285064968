import environment from "../Environments/Environmnet";

export const get_all_experiences= async ()=>{ 

    const token = 'Token '+localStorage.getItem('token')
    const requestOptions = {
         method: 'GET',
         headers: { 'Content-Type': 'application/json', Authorization: token },
         params: { }
     };
    
     var url=`${environment.server_root}/api/avatar/get_all_experiences/`
    //  var url=`${environment.server_root}/api/avatar/get_models?avatar__category=4`
     const response = await fetch(url, requestOptions);
     const data = await response.json();
    //  (data)
     return data;         
  };
