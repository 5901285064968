import React, { useEffect } from 'react'
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Box from '@mui/material/Box';
import * as colors from '../../Styles/Colors';
import '../../Styles/global.css'
import '../../Styles/Planets.css'


var cube,renderer,scene,camera,container,orbitControls;
const mouse = new THREE.Vector2();
const target = new THREE.Vector2();
const windowHalf = new THREE.Vector2( window.innerWidth / 2, window.innerHeight / 2 );
const spheres = [];
function createSphere()
{
    scene = new THREE.Scene();
    camera = new THREE.PerspectiveCamera(50, 500 / 400, 0.1, 1000);
    
    renderer = new THREE.WebGLRenderer({alpha: true,antialias: true})
    renderer.setSize(window.innerWidth, window.innerHeight)
    renderer.shadowMap.enabled = true
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.physicallyCorrectLights = true;
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    container.append(renderer.domElement)

    // orbitControls = new OrbitControls(camera, renderer.domElement);
    // orbitControls.enableDamping = true
    // orbitControls.panSpeed = 0.2;
    // orbitControls.dampingFactor = 0.3
    // orbitControls.maxDistance=1
    // orbitControls.maxPolarAngle= 2
    // orbitControls.minPolarAngle=1
    var ambientLight = new THREE.AmbientLight(0xFFFFFF);
    scene.add(ambientLight);
    var loader = new THREE.TextureLoader();
    loader.load( 'https://media-public.canva.com/vsiCI/MAEGqnvsiCI/1/s2.jpg', function ( texture ) {
        // console.log(texture)
        texture.repeat.set(2,4);
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;
        texture.needsUpdate = true;
        texture.flipY = false;
        texture.encoding = THREE.sRGBEncoding;
        texture.anisotropy = 16;
        texture.mapping = THREE.ACESFilmicToneMapping;
        var geometry = new THREE.SphereGeometry(2, 64, 32, 0, Math.PI * 2, 0, Math.PI * 2);
    
        var material = new THREE.MeshBasicMaterial( { map: texture,fog:false,color:0xFFFFFF} );
        
        var mesh = new THREE.Mesh( geometry, material );
        scene.add( mesh ); 
    } );
    loader.load('https://media-private.canva.com/JxMFI/MAFIoDJxMFI/1/s2.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJWF6QO3UH4PAAJ6Q%2F20220901%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20220901T174534Z&X-Amz-Expires=81033&X-Amz-Signature=b8e53ea96673c874750b19f8f92cdeb770006b6dc9cb074a5d44d331be95837f&X-Amz-SignedHeaders=host&response-expires=Fri%2C%2002%20Sep%202022%2016%3A16%3A07%20GMT', function ( texture ) {
        // console.log(texture)
        // texture.repeat.set(2,4);
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;
        texture.needsUpdate = true;
        texture.flipY = false;
        texture.encoding = THREE.sRGBEncoding;
        texture.anisotropy = 16;
        texture.mapping = THREE.ACESFilmicToneMapping;
        
        scene.background= texture;
        
    
    });
    
    const AmbientLight =new THREE.AmbientLight(0xFFFFFF,1)
    scene.add(AmbientLight)
    const PointLight = new THREE.PointLight(0xFFFFFF,1)
    PointLight.position.set(0,0,0);
    scene.add(PointLight)
    for ( let i = 0; i < 100; i ++ ) {
      const geometry = new THREE.SphereGeometry(Math.random()/100, 50, 32, 0, Math.PI * 2, 0, Math.PI * 2);
      const material = new THREE.MeshPhongMaterial({color:getDarkColor(),reflectivity:0.8,shininess: 400,});
      const object = new THREE.Mesh( geometry, material );
      object.position.x = Math.random()-0.5 
      object.position.y = Math.random()-0.5 
      object.position.z = Math.random()-0.5 
      object.rotation.x = Math.random() * 2 * Math.PI;
      object.rotation.y = Math.random() * 2 * Math.PI;
      object.rotation.z = Math.random() * 2 * Math.PI;
      const PointLight = new THREE.PointLight(0xFFFFFF,1)
      object.add(PointLight)
      scene.add( object );   
      spheres.push( object );      
		}

    
    // var geometry = new THREE.SphereGeometry(3, 50, 50, 0, Math.PI * 2, 0, Math.PI * 2);
    // var material = new THREE.MeshNormalMaterial();
    // cube = new THREE.Mesh(geometry, material);
    // scene.add(cube);
    
    camera.position.z = 0.5;
}
function getDarkColor() {
  var color = '#';
  for (var i = 0; i < 6; i++) {
      color += Math.floor(Math.random() * (3 - 0) + 0);
  }
  return color;
}
document.addEventListener( 'mousemove', onMouseMove, false );
// document.addEventListener( 'wheel', onMouseWheel, false );
function onMouseMove( event ) {
if(mouse){
  mouse.x = ( event.clientX - windowHalf.x );
	mouse.y = ( event.clientY - windowHalf.x );
}
	
}

// function onMouseWheel( event ) {
//   camera.position.z += event.deltaY * 0.1; // move camera along z-axis
// }

var render = function () {
    // orbitControls.update();
    requestAnimationFrame(render);
    const timer = 0.0001 * Date.now();

    target.x = ( 1 - mouse.x ) * 0.001;
    target.y = ( 1 - mouse.y ) * 0.001;
    // console.log(camera)
    // if(camera.position.z>0.5 || camera.position.z<-0.5){
    //   camera.position.z =0.5 // move camera along z-axis
    // }
    camera.rotation.x += 0.09 * ( target.y - camera.rotation.x );
    camera.rotation.y += 0.09 * ( target.x - camera.rotation.y );
    // cube.rotation.y += 0.01;
    for ( let i = 0, il = spheres.length; i < il; i ++ ) {

      const sphere = spheres[ i ];

      sphere.position.x = Math.cos( timer + i );
      sphere.position.y = Math.sin( timer + i * 1.1 );

    }
    renderer.render(scene, camera);
};

const BackgroundScene = () => {
    
    useEffect(()=>{
        container = document.getElementById('container3D')
        createSphere();
        render();
    },[])
    useEffect(()=>{
        window.addEventListener('resize', () => {
            // Update camera aspect
            camera.aspect = window.innerWidth / window.innerHeight
                  
            // Update camera projection matrix
            camera.updateProjectionMatrix()
                  
            // Resize renderer
            renderer.setSize(window.innerWidth, window.innerHeight)
          })
    })
  return (
    <div>
      <div id='container3D'></div>
      
      
    </div>
  )
}

export default BackgroundScene
