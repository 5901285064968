import React from "react";
import ReactGA4 from "react-ga4";

const useGAEventTracker = (category = "Category") => {
    const trackEvent = (action = "action", label = "label") => {
        ReactGA4.event({
            category,
            action,
            label,
        });
    };

    return trackEvent;
};

export default useGAEventTracker;
