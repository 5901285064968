import React, { useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import { deletekey } from '../LocalStorage/LocalStorage';
import * as colors from '../Styles/Colors';
import '../Styles/global.css'

const Logout = () => {
    
    const navigate=useNavigate();
    useEffect(()=>{
        localStorage.clear();
        navigate('/')
    },[])
  return (
    <div style={{color:colors.dark_blue_2,backgroundColor:colors.off_white_1,height:'100vh',marginTop:'0%'}}>
    <header className="gru-blue w3-row no-print">   
        <div className="w3-row" style={{width:'100%',backgroundColor:colors.dark_green_1}}>
            <div className="w3-col l2 m2 s2 w3-left">
                <img src="https://storage.googleapis.com/grubox-store/gret/img/logo.png" className="w3-circle w3-margin-right" style={{width:'30%'}}  alt=""/>
            </div>
        </div>
    </header>
    <div className="w3-center">
        <br></br>
            <img src="https://storage.googleapis.com/grubox-store/carousel/avatar.png" alt="Avatar" style={{width:'15%'}} className="w3-circle w3-margin-top"/>
        </div>
    <div className='w3-center'>
        <p>Hi there!, You have successfully logged out!</p>
        <p>Click Below to sign in again!!</p>
        <button className="signup_button" onClick={()=>navigate('/login')} style={{color:colors.off_white_1,backgroundColor:colors.dark_green_1,width:'20%'}}>
            Login Again
        </button>
        
    </div>
      
    </div>
  )
}

export default Logout
