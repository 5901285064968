import environment from "../Environments/Environmnet";
import { get } from "../LocalStorage/LocalStorage";

export const getLevelChallenges = async (level_selected)=>{
    const token = 'token '+localStorage.getItem('token')
    const requestOptions = {
         method: 'GET',
         headers: { 'Content-Type': 'application/json', Authorization: token },
         params: { "level": level_selected}
     };
     var url=`${environment.server_root}/api/challenge/get_client_challenges?level=`+level_selected
     const response = await fetch(url, requestOptions);
     const data = await response.json();
     return data
}

export const getAllChallenges = async (level_selected)=>{
    const token = 'token '+localStorage.getItem('token')
    const requestOptions = {
         method: 'GET',
         headers: { 'Content-Type': 'application/json', Authorization: token },
         params: { "level_number": level_selected}
     };
     var url=`${environment.server_root}/api/challenge/get_all_challenges?level_number=`+level_selected
     const response = await fetch(url, requestOptions);
     const data = await response.json();
     return data
}

export const updateClientDetails = async (data)=>{
    const token = 'token '+localStorage.getItem('token')
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: token },
        body: JSON.stringify(data)
    };
    var url=`${environment.server_root}/api/client/update_client_details/`
    const response = await fetch(url, requestOptions);
    const new_data = await response.json();
    return new_data
}

export const create_challenge = async (postData)=>{
    const token = 'token '+localStorage.getItem('token')
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: token },
        body: JSON.stringify(postData)
    };
    var url=`${environment.server_root}/api/challenge/create_challenge/`
    const response = await fetch(url, requestOptions);
    const new_data = await response.json();
    return new_data
}

