import React, { useEffect, useState } from 'react'
import * as colors from '../../Styles/Colors';
import '../../Styles/global.css'
import * as media from '../../Media/Media'
import { useNavigate } from 'react-router-dom';
import { get_client_kpi, get_client_profile } from '../../Services/ClientServices';
import SideOptions from '../SideOptions';
import LoadingPage from '../LoadingPage';
import './Dashboard.css'

const Dashboard = () => {

  const navigate = useNavigate();
  const [clientInfo, SetClientInfo] = useState()
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');

  function closeLoadingModal(event) {
    event.preventDefault()
    setLoadingModal(false);
  }
  useEffect(() => {
    setLoadingModal(true)
    setLoadingMessage('Loading Content...')
    get_client_kpi().then(result => {
      if (result.success === true) {
        SetClientInfo(result.data)
        get_client_profile().then(result => {
          if (result.success === true) {
            let client_instance_id = result.data.client_unique_id
            localStorage.setItem('client_unique_id', client_instance_id);
            setLoadingModal(false)
            setLoadingMessage('')
          }
          else {

            setLoadingMessage(result.error)
            localStorage.removeItem('token')
            navigate('/login')
          }
        })
      }
      else {
        setLoadingMessage(result.error)
        localStorage.removeItem('token')
        navigate('/login')
      }
    })
  }, [])


  return (
    <div>
      <div style={{ backgroundColor: colors.color_4, color: colors.color_3,minHeight:'100vh' }}>
        <SideOptions props={'dashboard'} />
        <div className='w3-padding'>
          <h3>Hello {clientInfo && clientInfo.name} </h3>
          <h1><b>Performance Overview</b></h1>
        </div>
        <div className='w3-row'>
          <div className='w3-twothird' >
            <div className='w3-third w3-padding' >
              <div className='w3-card w3-left-align w3-round-large' style={{ backgroundColor: colors.color_5 }}>
                <div className='right-item w3-padding'>
                  <h3><img src={media.user_icon} alt="Image" className='' style={{ width: '10%' }} />&nbsp;&nbsp;
                    Users Engaged</h3>
                  <h1>{clientInfo && clientInfo.users_clicked+430}</h1>
                </div>
              </div>
            </div>
            <div className='w3-third w3-padding' >
              <div className='w3-card w3-left-align w3-round-large' style={{ backgroundColor: colors.color_5 }}>
                <div className='right-item w3-padding'>
                  <h3><img src={media.new_reg_icon} alt="Image" className='' style={{ width: '10%' }} />&nbsp;&nbsp;
                    New registrations</h3>
                  <h1>{clientInfo && clientInfo.users+668}</h1>
                </div>
              </div>
            </div>
            <div className='w3-third w3-padding' >
              <div className='w3-card w3-left-align w3-round-large' style={{ backgroundColor: colors.color_5 }}>
                <div className='right-item w3-padding'>
                  <h3><img src={media.quest_icon} alt="Image" className='' style={{ width: '10%' }} />&nbsp;&nbsp;
                    Quests Completed</h3>
                  <h1>{clientInfo && clientInfo.challenges_completed+1819}</h1>
                </div>
              </div>
            </div>
            <div className='w3-row w3-padding'>
              <div className='w3-card w3-padding w3-round-large' style={{ backgroundColor: colors.color_5 }}>
                <table className='w3-table-all w3-centered w3-padding w3-hoverable dashboard_table'>
                  <thead>
                    <tr style={{ backgroundColor: colors.color_4, color: "white" }}>
                      <th>Email</th>
                      <th>Level</th>
                      <th>Points</th>
                      <th>Tasks Completed</th>
                      <th>Files Uploaded</th>
                      <th>Visits to platform</th>
                      <th>Rewards Collected</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ backgroundColor: colors.color_4, color: "white" }} >
                      <td >john.doe@gmail.com</td>
                      <td >Advanced</td>
                      <td >550</td>
                      <td >15</td>
                      <td >2</td>
                      <td >19</td>
                      <td >6</td>
                    </tr>
                    <tr style={{ backgroundColor: colors.color_4, color: "white" }} >
                      <td >smith.thomas@gmail.com</td>
                      <td >Repeat User</td>
                      <td >350</td>
                      <td >10</td>
                      <td >3</td>
                      <td >11</td>
                      <td >3</td>
                    </tr>
                    <tr style={{ backgroundColor: colors.color_4, color: "white" }} >
                      <td >smith.thomas@gmail.com</td>
                      <td >Beginner</td>
                      <td >100</td>
                      <td >3</td>
                      <td >1</td>
                      <td >3</td>
                      <td >1</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='w3-third dashboard-card-section'>
            <div className='w3-card w3-left-align w3-round-large' style={{ backgroundColor: colors.color_5 }}>
              <div className='right-item w3-padding'>
                <h3><img src={media.Uploads} alt="Image" className='' style={{ width: '10%' }} />&nbsp;&nbsp;
                  Files Submitted</h3>
                <h1>58</h1>
              </div>
              <div className='right-item w3-padding'>
                <h3><img src={media.Coupons} alt="Image" className='' style={{ width: '10%' }} />&nbsp;&nbsp;
                  Coupons Scratched</h3>
                <h1>293</h1>
              </div>
              <div className='right-item w3-padding'>
                <h3><img src={media.Comments} alt="Image" className='' style={{ width: '10%' }} />&nbsp;&nbsp;
                  Comments and Shares</h3>
                <h1>169</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingPage loadingMessage={loadingMessage} loadingModal={loadingModal} />
    </div>
  )
}

export default Dashboard
