import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as colors from '../../Styles/Colors'
import NavigationBar from '../NavigationBar/NavigationBar'
import { gret_icon, login_image } from '../../Media/Media'

const About = () => {
    const navigate = useNavigate()
    return (
        <div >
            <NavigationBar />
            <div className='w3-row black-bg ' >
                <div className='large-section' style={{ minHeight: '90vh', color: 'white',overflow:'auto' }}>
                    <h1 className='w3-center' style={{ fontSize: 42 }}><b>Steps for Gamification Implementation</b></h1>
                    <div className=''>
                        {Cards.map(item => (
                            <div className='w3-third w3-padding'>
                                <div className='w3-card w3-round-xlarge' style={{ backgroundColor: colors.color_3, color: colors.color_1, padding: 16 }}>
                                    <div className='w3-row'>
                                        <img src={login_image} style={{ height: '40vh', objectFit: 'cover' }} className='w3-col w3-round-xlarge' />
                                    </div>
                                    <div className='w3-container'>
                                        <p>Step {item.step}</p>
                                        <h2>{item.title}</h2>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About

const Cards = [
    {
        step: 1,
        title: 'Set up your design and preferences'
    },
    {
        step: 2,
        title: 'Set up Game Challenges, levels and points'
    },
    {
        step: 3,
        title: 'Copy code to your site and Start tracking'
    },
]