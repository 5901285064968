import './App.css';
import './Styles/global.css'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import LoginPage from './Screens/Signup&Login/LoginPage';
import GameficationDesign from './Screens/Gameification Screens/GameficationDesign';
import Quest from './Screens/QuestDashboard/Quest';
import Dashboard from './Screens/Dashboards/Dashboard';
import Design from './Screens/Design/Design';
import ClientProfile from './Screens/ClientProfile';
import Logout from './Screens/Logout';
import { initializeLocalStorage } from './LocalStorage/LocalStorage';
import React, { useEffect } from 'react';
import NewSignup from './Screens/Signup&Login/NewSignup';
import Stories from './Screens/SocialPlatformPages/Stories';
import ReactGA4 from "react-ga4";
import { GoogleOAuthProvider } from '@react-oauth/google';
import SelfProfile from './Screens/SocialPlatformPages/Profiles/SelfProfile';
import BackgroundScene from './Screens/SocialPlatformPages/BackgroundScene';
import DashboardBuildVR from './Screens/Dashboards/DashbordBuildVR';
import WelcomePage from './Screens/SocialPlatformPages/WelcomePage';
import CreateLandingPage from './Screens/LandingPage';
import LearnGamification from './Screens/About/About';
import Pricing from './Screens/Pricing/Pricing';
import ContactForm from './Screens/Contact/ContactForm';
ReactGA4.initialize("G-WL2B7S60Z6");
function handleCallbackResponse(response) {
  console.log(response.credentials)
}


const App = () => {
  useEffect(() => {
    initializeLocalStorage()
    /*global google*/
    // google.accounts.id.initialize({
    //   clientId:"707788443358-u05p46nssla3l8tmn58tpo9r5sommgks.apps.googleusercontent.com",
    //   callback:handleCallbackResponse
    // })

  }, [])

  useEffect(() => {
    ReactGA4.send({ hitType: "pageview", page: window.location.pathname });
  })
  return (
    <>
      <GoogleOAuthProvider clientId="451855764100-0qiomvvrsknpt3mlsjcjksc4hhurq78l.apps.googleusercontent.com">
        <Router>
          <Routes>
            {/*<Route exact path="/" element={<LandingPage/>}/>  */}
            {/* <Route exact path="/signup" element={<SignupPage/>}/>   */}
            <Route exact path="/" element={<CreateLandingPage />} />
            <Route exact path="/about" element={<LearnGamification />} />
            <Route exact path="/contact" element={<ContactForm />} />
            <Route exact path="/pricing" element={<Pricing />} />
            <Route exact path="/signup" element={<NewSignup />} />
            <Route exact path="/login" element={<LoginPage />} />
            {/* <Route exact path="/" element={<Create3d/>}/> */}
            <Route exact path="/profile" element={<ClientProfile />} />
            <Route exact path="/gamification" element={<GameficationDesign />} />
            <Route exact path="/quest" element={<Quest />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/design" element={<Design />} />
            <Route exact path="/logout" element={<Logout />} />
            <Route exact path="/stories" element={<Stories />} />
            <Route exact path="/userprofile" element={<SelfProfile />} />
            <Route exact path="/background" element={<BackgroundScene />} />
            <Route exact path="/dashboardBuildvr" element={<DashboardBuildVR />} />
            <Route exact path="/welcome" element={<WelcomePage />} />
          </Routes>
        </Router>
      </GoogleOAuthProvider>
    </>
  )
}

export default App