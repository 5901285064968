import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as colors from '../../Styles/Colors'
import NavigationBar from '../NavigationBar/NavigationBar'
import { gret_icon, login_image } from '../../Media/Media'
import './Pricing.css'

const Pricing = () => {
    const navigate = useNavigate()
    return (
        <div >
            <NavigationBar />
            <div className='w3-row black-bg ' >
                <div className='pricing-section' style={{ minHeight: '90vh', color: 'white', overflow: 'auto' }}>
                    <h1 className='w3-center' style={{ fontSize: 42 }}><b>Web Service Pricing Plans</b></h1>
                    <div className=''>
                        <div className='w3-third w3-padding'>
                            <div className='w3-card w3-round-xlarge' >
                                <div className='w3-row w3-center' style={{ backgroundColor: colors.color_2, borderRadius: '16px 16px 0px 0px' }}>
                                    <h2>Basic</h2>
                                    <p>Starter Website</p>
                                </div>
                                <div className='w3-container space-item-column' style={{borderRadius: '0px 0px 16px 16px', backgroundColor: colors.color_3, color: colors.color_1 }}>
                                    <div>

                                        <h3>Services Included</h3>
                                        <div className='w3-container'>
                                            <h3 ><i className="fa fa-check-circle"></i> 3-Page Website (Home, About, Contact)</h3>
                                            <h3 className='no-margin'><i className="fa fa-check-circle"></i> Responsive Design</h3>
                                            <h3 ><i className="fa fa-check-circle"></i> Basic SEO Setup</h3>
                                            <h3 className='no-margin'><i className="fa fa-check-circle"></i> Contact Form Integration</h3>
                                            <h3 ><i className="fa fa-check-circle"></i> 1 Round of Revisions</h3>
                                            <h3 className='no-margin'><i className="fa fa-check-circle"></i> 7 Days Turnaround Time</h3>
                                        </div>
                                    </div>
                                    <div className='w3-col w3-padding-large' style={{  }}>
                                        <div className='center-item-row' style={{ gap: 4 }}>
                                            <h1>$49 </h1>
                                            <p className='no-margin'>(one-time fee)</p>
                                        </div>
                                        <button className='button-dark'>Choose plan</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pricing
