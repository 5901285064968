import React, { useEffect, useState } from 'react'
import { getLevelChallenges, getAllChallenges, updateClientDetails, create_challenge } from '../../Services/ChallengesServices';
import * as colors from '../../Styles/Colors';
import '../../Styles/global.css'
import Modal from 'react-modal';
import { Oval } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom';
import * as media from '../../Media/Media'
import SideOptions from '../SideOptions';
import LoadingPage from '../LoadingPage';
import ReactSlider from "react-slider";

const Quest = () => {

  const QuestModalDesign = {
    content: {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '16px',
      width: 350,
      height: '50%',
      backgroundColor: colors.color_1,
      color: colors.color_3,
      padding: 10
    },
  };

  const [five_points_sel, setFive_points_sel] = useState(true);
  const [ten_points_sel, setTen_points_sel] = useState(false);
  const showLevel_id = [1, 2, 3];
  const [selectedLevel, setSelectedLevel] = useState(1);
  const [level_done, setLevel_done] = useState([false, false, false]);
  // const [quests, setQuests] = useState(null);
  const [questModal, setQuestModal] = useState(false);
  const [facebookModal, setFacebookModal] = useState(false);
  const [instaModal, setInstaModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [urlInsta, setUrlInsta] = useState('');
  const [urlFB, setUrlFB] = useState('');
  const [actions, setAction] = useState([]);
  const [values, setValues] = useState([]);
  const [pointsValue, setPointsValue] = useState(1)

  const type_options = [{ "C": "Comment/Short answer" }, { "R": "Upvote/Downvote" }];
  const navigate = useNavigate()

  const toggleSelected = (id) => {
    // loop over the todos list and find the provided id.
    let updatedList = actions.map(item => {
      if (item.id == id) {
        return { ...item, selected: !item.selected, newState: !item.newState }; //gets everything that was already in item, and updates "done"
      }
      return item; // else return unmodified item 
    });
    setAction(updatedList); // set state to new object with updated list
  }
  const changeLevel = (level) => {

    var current_level = level
    // //console.log(current_level)
    setPointsValue(5)
    setSelectedLevel(current_level)
    setAction(null)
    setLoadingModal(true)
    setLoadingMessage('Loading Content...')
    getAllChallenges(current_level).then(result => {
      if (result.success === true) {
        console.log(result)

        // setAction(result.data)
        // console.log(result)
        var quest = result.data
        quest = quest.map((level_quest) => {
          level_quest['selected'] = false
          level_quest['oldState'] = false
          level_quest['newState'] = false
          return level_quest
        })

        getLevelChallenges(current_level).then(result => {
          if (result.success === true) {
            var sel_actions = result.data;
            // console.log(result)
            for (var i = 0; i < sel_actions.length; i++) {
              sel_actions[i]['newState'] = true
              // sel_actions[i].oldState=true
              quest.push(sel_actions[i])
            }
            for (var i = 0; i < quest.length; i++) {
              for (var j = 0; j < sel_actions.length; j++) {
                if (quest[i].id === sel_actions[j].id) {
                  quest[i].selected = true;
                  quest[i].oldState = true;
                }
              }
            }
            // //console.log(quest)
            setAction(quest)
            if (sel_actions.length !== 0) {
              const level_done_new = [...level_done];
              level_done_new[current_level - 1] = true;
              setLevel_done(level_done_new);
            }

            setLoadingModal(false)
            setLoadingMessage('')
          }
          else {
            setLoadingModal(true)
            setLoadingMessage(result.error)
          }
        });
      }
      else {
        setLoadingModal(true)
        setLoadingMessage(result.error)
      }
    })
  }

  function openQuestModal() {

    setQuestModal(true);
  }
  function CloseQuestModal() {

    setQuestModal(false);
  }
  function openFacebookModal() {

    setFacebookModal(true);
  }
  function closeFacebookModal() {

    setFacebookModal(false);
  }
  function openInstaModal() {

    setInstaModal(true);
  }
  function closeInstaModal() {
    setInstaModal(false);
  }
  const changeUrl = (event) => {
    // event.preventDefault()
    // setUrl(event.target.value)
  }
  // Add New/Mega Challenge Section
  const handdleChangeChallenge = (event) => {

    const { name, value } = event.target;
    // // //console.log(name,value)
    setValues({
      ...values,
      [name]: value,
    });

  }
  function closeLoadingModal(event) {
    event.preventDefault()
    setLoadingModal(false);
  }
  const add_challenge = (event) => {
    event.preventDefault();
    //console.log(values.challenge_type)
    if (values.challenge_type !== null && values.description !== null && values.challenge_type !== undefined && values.description !== undefined) {
      const new_challenge = values;
      new_challenge['selected'] = true;
      new_challenge['oldState'] = false;
      new_challenge['newState'] = true;
      setValues(new_challenge)
      // //console.log(new_challenge)
      setAction(actions => [...actions, values])
      setValues([{ description: '', challenge_type: null, selected: true }])
      CloseQuestModal()
      setLoadingMessage('')
    }
    else if (values.challenge_type !== null || values.challenge_type !== undefined) {
      setLoadingMessage('Select Quest Type!')
    }
    else {
      setLoadingMessage('Enter a valid Input!')
    }

  }
  // //console.log(actions)
  const add_insta = (event) => {
    // event.preventDefault()
    // // //console.log({ "insta_page": url })
    // setLoadingModal(true)
    // setLoadingMessage('Saving Content...')
    // updateClientDetails({ "insta_page": url }).then(result=>{
    //   if(result.success===true){
    //     closeInstaModal();
    //     setLoadingModal(false)
    //     setLoadingMessage('')
    //   }
    //   else{
    //     setLoadingModal(true)
    //     setLoadingMessage(result.error)
    //   }
    // })
  }
  const add_facebook_insta_details = (event) => {
    event.preventDefault()
    setLoadingModal(true)
    setLoadingMessage('Saving Content...')
    var data = { "fb_page": urlFB, "insta_page": urlInsta }
    updateClientDetails(data).then(result => {
      if (result.success === true) {
        closeFacebookModal();
        setLoadingModal(false)
        setLoadingMessage('')
      }
      else {
        setLoadingModal(true)
        setLoadingMessage(result.error)
      }
    })
  }

  const updateChallenge = (event) => {
    event.preventDefault()
    // var points = 0;
    // if (five_points_sel)
    //     points += 5;
    // if (ten_points_sel)
    //     points += 10;
    // if (points === 0){
    //   // //console.log('error')
    // }
    // //console.log(actions)
    var new_actions = actions.filter(word => {
      // // //console.log(word)
      if (word.oldState === false && word.newState === true && word.selected === true) { return true }
      return false
    });
    // //console.log(new_actions)
    var inactive_challenge = actions.filter(word => (word.oldState === true && word.newState === false && word.selected === false));
    // //console.log(inactive_challenge)

    var i_c = [];
    for (var i = 0; i < inactive_challenge.length; i++)
      i_c.push(inactive_challenge[i]['id']);
    var postData = {
      "points": pointsValue, "level_number": selectedLevel,
      "challenges": new_actions, "inactive_challenges": i_c
    };
    // // //console.log(postData)
    setLoadingModal(true)
    setLoadingMessage('Saving Content...')
    create_challenge(postData).then(result => {
      // //console.log(result)
      if (result.success === true) {
        var selected_action = actions.filter(word => (word.selected === true))
        if (selected_action.length !== 0) {
          const level_done_new = [...level_done];
          level_done_new[selectedLevel - 1] = true;
          setLevel_done(level_done_new);
        }
        setLoadingModal(false)
        setLoadingMessage('')
        if (selectedLevel === 3) {
          navigate('/profile')
        }
        else {
          setSelectedLevel(selectedLevel + 1)
          changeLevel(selectedLevel + 1)
        }
      }
      else {
        setLoadingMessage(` ${result.error}!... Press ESC to continue`)
      }

    })

  }
  useEffect(() => {
    // var questSelected=false
    // if(actions!==null){
    //   for(var i=0;i<actions.length;i++)
    //   {

    //     if(actions[i].selected===true)
    //     {
    //       questSelected=true
    //       break;
    //     }
    //   }
    //   if(questSelected===true){
    //     const level_done_new = [...level_done];
    //     level_done_new[selectedLevel-1] = true;
    //     setLevel_done(level_done_new);
    //   }
    // }


  })
  // // //console.log(actions)
  useEffect(() => {
    setLoadingModal(true)
    setLoadingMessage('Loading Content...')
    getAllChallenges(selectedLevel).then(result => {
      if (result.success === true) {
        // setAction(result.data)
        var quest = result.data
        quest = quest.map((level_quest) => {
          level_quest['selected'] = false
          level_quest['oldState'] = false
          level_quest['newState'] = false
          return level_quest
        })
        const level_already_done = result.levels
        const level_done_new = [...level_done];
        for (var i = 0; i < level_already_done.length; i++) {
          level_done_new[level_already_done[i] - 1] = true
        }

        setLevel_done(level_done_new);
        // //console.log(quest)

        getLevelChallenges(selectedLevel).then(result => {

          if (result.success === true) {
            // setAction(result.data)
            var sel_actions = result.data;
            // //console.log(sel_actions)
            for (var i = 0; i < sel_actions.length; i++) {
              sel_actions[i]['newState'] = true
              // sel_actions[i].oldState=true
              quest.push(sel_actions[i])
            }
            for (var i = 0; i < quest.length; i++) {
              for (var j = 0; j < sel_actions.length; j++) {
                if (quest[i].id === sel_actions[j].id) {
                  quest[i].selected = true;
                  quest[i].oldState = true;
                }
              }
            }
            // //console.log(quest)
            setAction(quest)
            setLoadingModal(false)
            setLoadingMessage('')
          }
          else {
            setLoadingModal(true)
            setLoadingMessage(result.error)
          }
        });
      }
      else {
        setLoadingModal(true)
        setLoadingMessage(result.error)
      }
    })
  }, [])

  return (
    <div className='w3-row'>
      <div className='w3-row w3-center' style={{ color: colors.color_3, height: "100vh" }}>
        <div className='w3-col l3 s12 quest_border level_section' style={{ backgroundColor: colors.color_4 }}>
          <h1>Setup Step 2</h1>
          <button className='level_button' onClick={() => navigate('/profile')}>Go to Step 3</button>
          <br></br>
          <br></br>
          {window.innerWidth > 600 && <>

            <br></br>
            <br></br>
            <h1><b>Game Levels</b></h1>
            {showLevel_id.map((level) =>
              level_done[level - 1] === false ?
                <button key={level} value={level} onClick={() => changeLevel(level)} className="level_button" style={{ width: '70%', marginTop: '10%' }} title="Click here to Select Level for adding quests">
                  Add Level {level} Quests
                </button>
                :
                <div key={level}>
                  <button value={level} onClick={() => changeLevel(level)} className="level_button" style={{ width: '70%', marginTop: '10%' }} title="Click here to update selected quests">
                    <div style={{ display: 'grid', gridTemplateColumns: '80% 20%' }}>
                      <div>
                        Add Level {level} Quests
                      </div>
                      <div className='level_tickmark'></div>
                    </div>
                  </button>
                </div>

            )}
            <button className="level_button" onClick={openQuestModal} style={{ width: '70%', marginTop: '10%' }} title="Add custom Quest to this Level" >Add Mega Quests  </button>
          </>}
        </div>
        <div className='w3-col l7 s12 quest_border' style={{ color: colors.color_3, backgroundColor: colors.color_2 }}>
          <div className="">
            <div className="heading_text">
              <h1><b>User Engagement Activities</b></h1>
            </div>

            {/* <div>
            <button className='link_button' style={{padding:'0%'}} onClick={()=>{navigate('/dashboard')}}>
              <h1 className="save_icon"><i className="fa fa-home" style={{color:colors.off_white_1}}></i></h1>
            </button> &nbsp;&nbsp;
            <button className='link_button' style={{padding:'0%'}}>
              <h1 className="save_icon"><i className="fa fa-floppy-o" style={{color:colors.off_white_1}} onClick={updateChallenge}></i></h1>
            </button>
            </div> */}

          </div>

          <p style={{ marginLeft: '15%', marginRight: '15%' }}>
            Currently there are 3 Levels in the User Journey - Onboarding, Repeat & Champion/Mature Users</p>
          <p style={{ margin: 0 }}>
            On completion of Engagement Activities, users win rewards and points.
            Mega Quests can earn booster points.</p>
          <h2><b>Level {selectedLevel}
            {selectedLevel === 1 && " (Onboarding)"}
            {selectedLevel === 2 && " (Repeat User)"}
            {selectedLevel === 3 && " (Champion User)"}
          </b></h2>
          <hr />
          <br></br>

          {actions !== null &&
            <div className="container">
              {
                actions.map((action) =>
                  <label key={action.id ? action.id : 1} className="option_item" style={{ margin: " 3% 0% 3% 0%" }} title="Click to add or remove this challenge">
                    <input type="checkbox" className="checkbox" onChange={() => toggleSelected(action.id)} checked={action.selected} />
                    <div className="option_inner facebook">
                      <div className="tickmark"></div>
                      <div>{action.description}
                        <p style={{ margin: 0 }}>Response Type:&nbsp;
                          {action.challenge_type === 'R' && 'Rating'}
                          {action.challenge_type === 'C' && 'Comment'}
                          {action.challenge_type === 'U' && 'Upload'}
                        </p>
                      </div>
                    </div>
                  </label>
                )}
              <button className="questAddnew" onClick={openQuestModal} title="Add custom quest for this level" >Add New </button>
            </div>
          }
          <h2><b>Rewards</b></h2>
          <p style={{ margin: 0 }}>For winning the quests user will get</p>
          <h3>Points</h3>
          <hr />
          <div className='w3-row'>
            <div className='w3-col l1 s1'><h1>5</h1></div>
            <div className='w3-col l10 s9'>
              <h1></h1>
              <ReactSlider
                step={5}
                min={5}
                max={50}
                className="horizontal-slider"
                thumbClassName="example-thumb"
                trackClassName="example-track"
                value={pointsValue}
                renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                onChange={(value) => {
                  setPointsValue(value)
                  // SetSizeVideo('video')
                }}
              />

            </div>
            <div className='w3-col l1 s1'><h1>50</h1></div>
          </div>


          {/* <div className="wrapper"style={{marginLeft: '25%',marginTop: '-5%'}}>  */}
          {/* <div className="container">
            <label className="option_item" style={{margin:" 3% 0% 3% 0%"}} title="Add points for this level">
              <input type="checkbox" className="checkbox" onChange={() => setFive_points_sel(!five_points_sel)} checked={five_points_sel} />
              <div className="option_inner facebook" >
                <div className="tickmark"></div>
                <div  >5 Points</div>
              </div>
            </label>

            <label className="option_item" style={{margin:" 3% 0% 3% 0%"}} title="Add points for this level">
              <input type="checkbox" className="checkbox" onChange={() => setTen_points_sel(!ten_points_sel)} checked={ten_points_sel}/>
              <div className="option_inner facebook" >
                <div className="tickmark"></div>
                <div >10 Points</div>
              </div>
            </label>
          </div> */}
          <button className="button-black" onClick={updateChallenge} style={{ width: '30%' }} title="Save quests and move to next level">Save & Next </button>

          {/* </div> */}




        </div>



        <div className='w3-col l2 s12 w3-center' style={{ backgroundColor: colors.color_2, height: "100vh", color: colors.color_3,overflow:'auto' }}>

          <h1><b>Rewards</b></h1>

          <div>
            <h3>Coupons</h3>
            <hr style={{ borderTop: "2px solid var(--color-3)", margin: "5px 15%" }} />
            <p style={{ margin: 0 }}>Coupon code</p>
            <input className='form_input' style={{ marginLeft: '12%' }} type="text" placeholder='Add coupon code' name="name" />
            <p style={{ margin: 0 }}>Coupon description</p>
            <input className='form_input' style={{ marginLeft: '12%' }} type="text" placeholder='Add coupon description' name="name" />
          </div>
          <div>
            <h3>Badges</h3>
            <hr style={{ borderTop: "2px solid var(--color-3)", margin: "5px 15%" }} />
            <p style={{ margin: 0 }}>Minimum points </p>
            <input className='form_input' style={{ marginLeft: '12%' }} type="text" placeholder='Minimum points for the badge' name="name" />
            <p style={{ margin: 0 }}>Name</p>
            <input className='form_input' style={{ marginLeft: '12%' }} type="text" placeholder='Name of the badge' name="name" />
            <p style={{ margin: 0 }}>Image URL</p>
            <input className='form_input' style={{ marginLeft: '12%' }} type="text" placeholder='Badge Image URL' name="name" />
          </div>
          <div>
            <h3>Social Rewards</h3>
            <hr style={{ borderTop: "2px solid var(--color-3)", margin: "5px 15%" }} />
            <p style={{ margin: 0 }}>Let users discover you here</p> 
          </div>
          <p style={{ margin: 0 }}>Facebook</p>
          <input className='form_input' onChange={(e) => setUrlFB(e.target.value)} style={{ marginLeft: '12%' }} type="text" placeholder='Add your facebook URL' name="name" />
          <p style={{ margin: 0 }}>Insta</p>
          <input className='form_input' onChange={(e) => setUrlInsta(e.target.value)} style={{ marginLeft: '12%' }} type="text" placeholder='Add your Insta URL' name="name" />
          <button className="level_button" style={{ width: '80%', marginTop: '2%', color: colors.color_3, backgroundColor: colors.color_1, }} onClick={add_facebook_insta_details} title="">Save Details</button>
          <div>
            <p>Reward your users with points for completing a activity at this level</p>
            <hr />
            <p>4 Letter Word Puzzle</p>
            <button className="level_button" style={{ width: '80%', marginTop: '2%', color: colors.color_3, backgroundColor: colors.color_1, }} onClick={() => window.open("https://forms.gle/5ptopkvmdSUJpsMi6", "blank")} title="">Add Other </button>
          </div>
        </div>

        <br></br>
        <SideOptions props={'quest'} />
        <Modal
          isOpen={questModal}
          onRequestClose={CloseQuestModal}
          style={QuestModalDesign}
          contentLabel="Example Modal"
        >
          <div className='w3-row'>
            <button onClick={CloseQuestModal} className='modal_close w3-right'> <i className='fa fa-close'> </i></button>
          </div>
          <div className='w3-row w3-left-align login_margin'>
            <label className='w3-padding w3-col'>Create a new Quest
              <input className='outline-light w3-col' name='description' onChange={(e) => handdleChangeChallenge(e)} type="text" />
            </label>
            <label className='w3-padding w3-col'>Quest Type
              <select className='outline-light w3-col' defaultValue="" name='challenge_type' onChange={(e) => handdleChangeChallenge(e)}  >
                <option value="" disabled>Choose an Option</option>
                <option value="C">Comment/Short answer</option>
                <option value="R">Upvote/Downvote</option>
                <option value="U">Upload Image/Video</option>
              </select>
            </label>
            <p style={{ textAlign: 'center', margin: '0%' }}>{loadingMessage}</p>
            <div className='w3-col w3-padding'>
              <button className="button-dark" onClick={add_challenge}>Create Quest</button>
            </div>
          </div>

        </Modal>

        {/* <Modal
          isOpen={instaModal}
          onRequestClose={closeInstaModal}
          style={SocialModalDesign}
          contentLabel="Example Modal"
        >
        
          <button onClick={closeInstaModal} className='modal_close'> <i className='fa fa-close'> </i></button>
          <br></br>
          <div style={{ backgroundColor: 'white', borderRadius: '8px', padding: '1%', width: '90%', marginLeft: '5%', color: colors.dark_blue_1 }}>

            <p style={{ textAlign: 'center' }}>Add Insta URL</p>
            <input className='signup_input' onChange={changeUrl} style={{ marginLeft: '12%' }} type="text" name="name" />
            <button className="level_button" onClick={add_insta} style={{ width: '75%', marginLeft: '12%', marginTop: '6%', backgroundColor: colors.dark_blue_2, color: colors.off_white_1 }} >Add Url</button>
            <br></br>
            <br></br>
          </div>

        </Modal>  */}
        <LoadingPage loadingMessage={loadingMessage} loadingModal={loadingModal} />

      </div>
    </div>
  )
}

export default Quest
