import React, { useEffect, useState } from 'react'
import * as colors from '../../Styles/Colors';
import '../../Styles/global.css'
import '../../Styles/media_queries.css'
import { updateClientDetails } from '../../Services/ChallengesServices';
import * as media from '../../Media/Media'
import Modal from 'react-modal';
import { Oval } from 'react-loader-spinner'
import { get_client_details, get_client_profile } from '../../Services/ClientServices';
import { useNavigate } from 'react-router-dom';
import SideOptions from '../SideOptions';
import FontInput from './Components/FontInput';
import { Fonts } from './Components/FontList'


const Design = () => {


    const screens = [1, 2, 3, 4, 5, 6, 7, 8, 9]
    const [colorTheme, SetColorTheme] = useState()
    const [fontInfo, SetFontInfo] = useState()
    const [clientInfo, SetClientInfo] = useState()
    const [message, setMessage] = useState('')
    const [loadingModal, setLoadingModal] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [screenParam, setScreenParam] = useState('');
    const [loadIframe, setLoadIframe] = useState(true);
    const [currentScreen, setCurrentScreen] = useState(1);
    const [instance_id, setInstanceId] = useState(null)
    const [selectedLayout, setSelectedLayout] = useState('right')
    const navigate = useNavigate();

    const handleColorThemeChange = (event) => {
        const { name, value } = event.target;

        SetColorTheme({
            ...colorTheme,
            [name]: value,
        });
    }

    const handleFontInfoChange = (event) => {
        const { name, value } = event.target;
        SetFontInfo({
            ...fontInfo,
            [name]: value,
        });
    }

    function closeLoadingModal(event) {
        event.preventDefault()
        setLoadingModal(false);
    }
    const updateColorTheme = () => {
        setLoadingModal(true)
        setLoadingMessage('Saving Content...')
        setLoadIframe(true)
        updateClientDetails(colorTheme).then(result => {
            if (result.success === true) {
                setLoadingModal(false)
                setLoadingMessage('')
                setLoadIframe(false)
                //   window.location.reload();
            }
            else {
                setLoadingMessage(result.error)
            }
        })

    }

    const changeFont = (key, value) => {
        SetFontInfo(fontInfo => ({ ...fontInfo, [key]: value }))
    }

    useEffect(() => {
        updateColorTheme()
    }, [colorTheme])

    const switchScreen = (action) => {
        if (currentScreen > 1 && action === '1') {
            setCurrentScreen(changeScreen - 1)
            changeScreen(currentScreen - 1)
        }
        if (currentScreen < 9 && action === '0') {
            setCurrentScreen(changeScreen - 1)
            changeScreen(currentScreen + 1)
        }
    }
    const updateFontInfo = () => {
        setLoadingModal(true)
        setLoadingMessage('Saving Content...')
        setLoadIframe(true)
        updateClientDetails(fontInfo).then(result => {
            if (result.success === true) {
                setLoadingModal(false)
                setMessage('')
                setLoadIframe(false)
                //   window.location.reload();
            }
            else {
                setLoadingMessage(result.error)
            }
        })

    }

    useEffect(() => {
        updateFontInfo()
    }, [fontInfo])
    const allowDrop = (ev) => {
        ev.preventDefault();
    }

    const drag = (ev) => {
        ev.dataTransfer.setData("text", ev.target.id);
        setMessage('Drag here');
    }

    const drop = (ev) => {
        ev.preventDefault();
        var data = ev.dataTransfer.getData("text");
        ev.target.appendChild(document.getElementById(data));
        setMessage('')
    }

    function postCrossDomainMessage(msg) {
        var win = document.getElementById("ifr").contentWindow;
        // win.postMessage(msg, "http://localhost:3001");

        win.postMessage(msg, "https://game.gretxp.com/");
    }
    function loadToken() {
        var postMsg = (localStorage.getItem('token')); // this is just example
        postCrossDomainMessage(postMsg);
    }
    function clearToken() {
        var postMsg = null; // this is just example
        postCrossDomainMessage(postMsg);
    }

    const changeScreen = (screen_number) => {
        if (screen_number === 1) {
            clearToken()
            setCurrentScreen(1)
            setScreenParam('')
        }
        else if (screen_number === 2) {
            clearToken()
            setCurrentScreen(2)
            setScreenParam('signin')
        }
        if (screen_number === 3) {
            // duplicateToken()
            loadToken()
            setCurrentScreen(3)
            setScreenParam('letsBegin')
        }
        else if (screen_number === 4) {
            loadToken()
            setCurrentScreen(4)
            setScreenParam('jumbled_words')
        }
        else if (screen_number === 5) {
            setCurrentScreen(5)
            setScreenParam('quest_rating')
        }
        else if (screen_number === 6) {
            setCurrentScreen(6)
            setScreenParam('quest_comment')
        }
        else if (screen_number === 7) {
            setCurrentScreen(7)
            setScreenParam('coupon')
        }
        else if (screen_number === 8) {
            setCurrentScreen(8)
            setScreenParam('points')
        }
        else if (screen_number === 9) {
            loadToken()
            setCurrentScreen(9)
            setScreenParam('profile')
        }

    }

    useEffect(() => {
        setLoadingModal(true)
        setLoadingMessage('Loading Content...')
        clearToken();
        get_client_profile().then(result => {
            if (result.success === true) {
                let client_instance_id = result.data.client_unique_id
                localStorage.setItem('client_unique_id', client_instance_id);
                setInstanceId(client_instance_id)
                get_client_details().then(result => {
                    if (result.success === true) {
                        SetClientInfo(result.data)
                        setLoadingModal(false)
                        setMessage('')
                        setLoadIframe(false)
                    }
                    else {
                        setLoadingMessage(result.error)
                    }
                })
            }
            else {

                setLoadingMessage(result.error)
                localStorage.removeItem('token')
                navigate('/login')
            }
        })

    }, [])

    return (
        <div>
            <div className=' w3-center designPageLayout' style={{ color: colors.color_3, backgroundColor: colors.color_2 }}>
                <div>
                    <h1>Setup Step 1</h1>
                    <button className='button-dark' onClick={() => navigate('/quest')}>Go to Step 2</button>
                    <SideOptions props={'design'} />
                </div>
                <div className='quest_border' style={{ borderRight: '2px solid var(--dark-green-1)', color: colors.color_3 }}>
                    <div className='w3-row'>
                        <h1><b>Customise Design</b></h1>
                        <p style={{ margin: 0 }}>Choose the design theme and assets. </p>
                        <h3 style={{ margin: 0 }}><b>Preview</b></h3>
                    </div>

                    {/* <div className='w3-row'>
                        <div className="ScreenCount">
                            <br></br>
                            <h3><b>Popup Screens</b></h3>
                            {screens.map((number) =>
                                <button key={number} value={number} onClick={() => changeScreen(number)} className="design_button" style={{ marginTop: 0, textDecoration: `${currentScreen === number ? 'underline' : 'none'}`, fontSize: `${currentScreen === number ? '25px' : '20px'}` }}>{number} </button>
                            )}
                        </div>
                    </div> */}
                    <div className='w3-container center-item-row' style={{ height: '70%' }}>
                        <div className='w3-col l4 s1'>
                            <h1><b><i className="fa fa-chevron-left" onClick={() => { switchScreen('1') }} style={{ cursor: `${currentScreen !== 1 ? "pointer" : ""}` }}></i></b></h1>

                        </div>
                        <div disabled={true} className='w3-center w3-col l4 s10'
                            style={{ pointerEvents: 'none' }}>
                            {/* <Test/> */}
                            { }
                            <iframe id='iframe' src={loadIframe ? '' : `https://game.gretxp.com/${screenParam}?details=${instance_id}`} scrolling="no" frameBorder="0" style={{ height: '70vh', width: '100%', borderRadius: 22 }}></iframe>
                            {/* <iframe id='iframe' src={loadIframe?'':`http://localhost:3001/${screenParam}?details=${instance_id}`} scrolling="no" frameBorder="0" style={{height:'100%',width:'100%',}}></iframe> */}

                            {message !== '' && message}
                            <div id="div1" onDrop={(event) => drop(event)} onDragOver={(event) => allowDrop(event)}></div>
                            <br></br>
                        </div>
                        <div className='w3-col l4 s1'>
                            <h1><b>
                                <i className="fa fa-chevron-right" onClick={() => { switchScreen('0') }} style={{ cursor: `${currentScreen !== 9 ? "pointer" : ""}` }}></i>
                            </b></h1>
                        </div>
                    </div>

                </div>


                <div className='w3-center' style={{ color: colors.color_3, backgroundColor: colors.color_1 }}>
                    <h1><b>Design Settings</b></h1>
                    <div className='w3-row' style={{ height: '90vh', overflow: 'auto' }}>

                        {clientInfo &&
                            <div>
                                <div>
                                    <h3>Color Theme</h3>
                                    <div className='center-item'>
                                        <hr className='w3-col l10 s10' style={{ borderTop: ' 2px solid var(--color-3)', margin: 0 }} />
                                    </div>
                                    <div className='w3-row w3-padding w3-left-align'>
                                        <label className='w3-col l4 s4'>Heading
                                            <input defaultValue={clientInfo.dark_color_1} className='color_input w3-col l11 s11' type="color" name='dark_color_1' onChange={handleColorThemeChange} placeholder='HEX CODE' />
                                        </label>
                                        <label className='w3-col l4 s4'>Paragraph
                                            <input defaultValue={clientInfo.light_color_2} className='color_input w3-col l11 s11' type="color" name='light_color_2' onChange={handleColorThemeChange} placeholder='HEX CODE' />
                                        </label>
                                        <label className='w3-col l4 s4'>Background
                                            <input defaultValue={clientInfo.light_color_1} className='color_input w3-col l12 s12' type="color" name='light_color_1' onChange={handleColorThemeChange} placeholder='HEX CODE' />
                                        </label>
                                        <label className='w3-col' style={{ paddingTop: 4 }}>Input Fields/ Button
                                            <input defaultValue={clientInfo.dark_color_2} className='color_input w3-col l12 s12' type="color" name='dark_color_2' onChange={handleColorThemeChange} placeholder='HEX CODE' />
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <h3>Fonts</h3>
                                    <div className='center-item'>
                                        <hr className='w3-col l10 s10' style={{ borderTop: ' 2px solid var(--color-3)', margin: 0 }} />
                                    </div>
                                    <div className='w3-padding w3-left-align'>
                                        <label >Heading 1</label>
                                        <div className='w3-row'>
                                            <div className='w3-col l10 s10'>
                                                <div className='w3-col l11 s11'>
                                                    <FontInput options={Fonts} defaultValue={clientInfo.font_family_head1} keyValue='font_family_head1' onChange={changeFont} />
                                                </div>
                                            </div>
                                            <input defaultValue={clientInfo.font_size_head1} className='outline-light w3-col l2 s2' type="text" placeholder='Size' name='font_size_head1' onChange={handleFontInfoChange} />
                                        </div>
                                        <label >Heading 2</label>
                                        <div className='w3-row'>
                                            <div className='w3-col l10 s10'>
                                                <div className='w3-col l11 s11'>
                                                    <FontInput options={Fonts} defaultValue={clientInfo.font_family_head2} keyValue='font_family_head2' onChange={changeFont} />
                                                </div>
                                            </div>
                                            <input defaultValue={clientInfo.font_size_head2} className='outline-light w3-col l2 s2' type="text" placeholder='Size' name='font_size_head2' onChange={handleFontInfoChange} />
                                        </div>
                                        <label >Paragraph</label>
                                        <div className='w3-row'>
                                            <div className='w3-col l10 s10'>
                                                <div className='w3-col l11 s11'>
                                                    <FontInput options={Fonts} defaultValue={clientInfo.font_family_para} keyValue='font_family_para' onChange={changeFont} />
                                                </div>
                                            </div>
                                            <input defaultValue={clientInfo.font_size_para} className='outline-light w3-col l2 s2' type="text" placeholder='Size' name='font_size_para' onChange={handleFontInfoChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        <div>
                            <h3>Design Elements</h3>
                            <div className='center-item'>
                                <hr style={{ borderTop: ' 2px solid var(--color-3)', margin: 0 }} />
                                <div className='w3-row w3-padding'>
                                    <input className='custom-file-input w3-col w3-padding w3-round-large' style={{backgroundColor:colors.color_2}} type='file' />
                                </div>
                            </div>
                            <h3>Layout</h3>
                            <div className='center-item'>
                                <hr style={{ borderTop: ' 2px solid var(--color-3)', margin: 0 }} />
                            </div>
                            <div className='w3-row w3-padding' style={{ height: '20vh', overflow: 'auto' }}>
                                {overlayOptions.map(item => (
                                    <button className='link_button w3-col' style={{ paddingBottom: 4 }} onClick={() => setSelectedLayout(item.value)}>
                                        <div className='w3-row w3-padding w3-card w3-round-large w3-container' style={{ backgroundColor: colors.color_2,color:colors.color_3 }}>
                                            <p className='w3-col l2 s2 w3-left-align no-margin'>
                                                {item.value === selectedLayout ?
                                                    <i className="fa fa-check-circle"></i>
                                                    :
                                                    <i className="fa fa-circle-o"></i>
                                                }
                                            </p>
                                            <p className='w3-col l10 s10 w3-left-align no-margin'>{item.label}</p>
                                        </div>
                                    </button>
                                ))}
                            </div>

                            {/* <div className="w3-panel w3-card w3-round-large" style={{width:'30%'}}>
                
                <img id="drag1 w3-card" src={media.LandingImage} draggable="true" onDragStart={(event) => drag(event)} width="100%" height="100%"></img>
            </div> */}
                        </div>
                    </div>

                </div>
            </div>
            {/* <LoadingPage loadingMessage={loadingMessage} loadingModal={loadingModal} /> */}

        </div>
    )
}

export default Design

const overlayOptions = [
    { value: 'topleft', label: 'Top Left' },
    { value: 'topright', label: 'Top Right' },
    { value: 'bottomleft', label: 'Bottom Left' },
    { value: 'bottomright', label: 'Bottom Right' },
    { value: 'left', label: 'Left' },
    { value: 'right', label: 'Right' },
    { value: 'middle', label: 'Middle' },
    { value: 'topmiddle', label: 'Top Mid' },
    { value: 'bottommiddle', label: 'Bottom Mid' },
]

