import React from "react";
import environment from "../Environments/Environmnet";
import { get } from "../LocalStorage/LocalStorage";

export const send_activation_code= async (email)=>{
    let user_email={email};        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email:user_email.email })
        };
            const response = await fetch(`${environment.server_root}/api/profile/send_activation_code/`, requestOptions);
            const data = await response.json();
            return data
}

export const register_client= async (client)=>{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(client)
    };
    const response = await fetch(`${environment.server_root}/api/client/register_client_nf/`, requestOptions);
    const data = await response.json();
    return data
}

export const get_client_kpi = async (level_selected)=>{
    const token = 'token '+localStorage.getItem('token')
    console.log(token)
    const requestOptions = {
         method: 'GET',
         headers: { 'Content-Type': 'application/json', Authorization: token },
         params: { "level_number": level_selected}
     };
     var url=`${environment.server_root}/api/client/get_client_kpi`
     const response = await fetch(url, requestOptions);
     const data = await response.json(); 
     return data
}

export const get_client_profile = async (level_selected)=>{
    const token = 'token '+localStorage.getItem('token')
    const requestOptions = {
         method: 'GET',
         headers: { 'Content-Type': 'application/json', Authorization: token },
         params: { "level_number": level_selected}
     };
     var url=`${environment.server_root}/api/client/get_client_profile`
     const response = await fetch(url, requestOptions);
     const data = await response.json();
     return data
}

export const get_client_details= async ()=>{
       
    const token = 'token '+localStorage.getItem('token')
    const client_unique_id= localStorage.getItem('client_unique_id')    
    const requestOptions = {
         method: 'GET',
         headers: { 'Content-Type': 'application/json', Authorization: token },
         params: { client_unique_id: client_unique_id}
    };
    
    var url=`${environment.server_root}/api/client/get_client_details?client_unique_id=`+client_unique_id
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    let client_details_data=data.data
    return data;         
}