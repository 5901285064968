import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import * as colors from '../../Styles/Colors';
import '../../Styles/global.css'
import * as media from '../../Media/Media'

const GameficationDesign = () => {
    const navigate=useNavigate()
    const[count,setCount]=useState(0);
    const UpdateCount=(event)=>{
        event.preventDefault()
        setCount(count+1)
    }
    const MoveToDashboard=(event)=>{
      localStorage.setItem("loginType","Gamification")
      navigate('/signup') 
    }
    // useEffect(()=>{
    //   if(localStorage.getItem("token"))
    //   {
    //     navigate("/design")
    //   }
    // })
  return (
    <div>
       <div className="w3-row w3-border">
        <div className="w3-container w3-half w3-center left_section" style={{backgroundColor:colors.dark_blue_4,color:'black',paddingTop:'8%'}}>
          <h1 style={{marginRight: '10%',marginLeft: '10%',fontWeight:'bold'}}> 
          Welcome to Gretxp 
          </h1>
          {
            count===0 && 
            <img src={media.intro_image_1} style={{width:'70%',marginTop: '5%'}}/>
          }
          {
            count===1 && 
            <img src={media.intro_image_3} style={{width:'70%',marginTop: '5%'}}/>
          }
          {
            count===2 && 
            <img src={media.intro_image_2} style={{width:'70%',marginTop: '5%'}}/>
          }
          {
            count===3 && 
            <>
            <video id="video" autoPlay muted={true} webkit-playsinline="true" crossOrigin="anonymous" loop style={{height:"60%",width:"65%"}} >
              <source src={media.intro_image_5} type='video/mp4'/>
            </video>
            {/* <iframe scrolling="no" frameBorder="0" src={`${media.intro_image_5}?autoplay=1&cc_load_policy=1`} style={{width: '65%'}} title="description" height="50%"></iframe> */}

            </>
            // <img src= style={{width:'70%',marginTop: '5%'}}/>
          }
        </div>
        <div className="w3-container w3-half w3-center right_section" style={{backgroundColor:colors.off_white_1,color:colors.dark_blue_3}}>
            <div style={{marginRight: '10%',marginLeft: '10%',marginTop: '9%'}}>
            <h1 className="right_section1">
                {count===0 &&  <b> Gamification</b>}
                {count===1 &&  <b> Add Game Elements to your website</b>}
                {count===2 &&  <b> Reward users for Engagement </b>}
                {count===3 &&  <b> Add fun with Puzzles & Games</b>}
               
            </h1> 
            {count===0 && 
            <div>
            <p>
            Every Game has Levels, Points, Quests and Rewards.
            </p>
            <p>Gretxp Gamification at websites increases  users, registrations and social shares.</p>
          </div> } 
          { count===1 &&
          <div>
          <p>At every level, users solves a few game, puzzle and/or interactive engagement activities.</p>
        </div>
          }
          { count===2 &&
          <div>
          <p>Example of a engagement activity is "Subscribe our newsletter", "Follow us on Facebook".</p>
          <p>On completion, the user gets points and may unlock new coupons.</p>
        </div>
          }
          { count===3 &&
          <div>
          <p>Example of puzzle: </p>
          <p> "Rearrange letters "L O G D"</p>
          <p> Answer: GOLD </p>
        </div>
          }          
         
            <br></br>
            {count<=2 ?
            <div>
                <button className="signup_button" onClick={UpdateCount}style={{color:colors.dark_green_1,backgroundColor:colors.dark_blue_4,width:'60%'}} >
            Next &gt;
            </button>
            </div>:
            <div>
            <button className="signup_button" onClick={MoveToDashboard} style={{color:colors.dark_green_1,backgroundColor:colors.dark_blue_4,width:'60%'}} >
            Design now!
            </button>
            </div>
            
            }
           
          
            <a href="https://www.gretxp.com/help"><button className='link_button' style={{bottom: '5%',right: '5%', position: 'absolute'}}>Help?</button></a>
            </div>
            
        </div>
    </div>
    </div>
  )
}

export default GameficationDesign
