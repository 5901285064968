import React, { useEffect } from 'react'
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Box from '@mui/material/Box';
import * as colors from '../../Styles/Colors';
import '../../Styles/global.css'
import '../../Styles/Planets.css'


var cube,renderer,scene,camera,container,orbitControls;
const mouse = new THREE.Vector2();
const target = new THREE.Vector2();
const windowHalf = new THREE.Vector2( window.innerWidth / 2, window.innerHeight / 2 );
const spheres = [];

function createSphere()
{
    scene = new THREE.Scene();
    camera = new THREE.PerspectiveCamera(50, 500 / 400, 0.1, 1000);
    
    renderer = new THREE.WebGLRenderer({alpha: true,antialias: true})
    renderer.setSize(window.innerWidth, window.innerHeight)
    renderer.shadowMap.enabled = true
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.physicallyCorrectLights = true;
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    container.append(renderer.domElement)

    orbitControls = new OrbitControls(camera, renderer.domElement);
    orbitControls.enableDamping = true
    orbitControls.panSpeed = 0.2;
    orbitControls.dampingFactor = 0.3
    // orbitControls.maxDistance=1
    // orbitControls.maxPolarAngle= 2
    // orbitControls.minPolarAngle=1
    var ambientLight = new THREE.AmbientLight(0xFFFFFF);
    scene.add(ambientLight);
    var loader = new THREE.TextureLoader();
    // loader.load( 'https://storage.googleapis.com/grubox-store/gret/local/avatar/dog/Untitled_design_1.png', function ( texture ) {
    //     // console.log(texture)
    //     texture.repeat.set(2,4);
    //     texture.wrapS = THREE.RepeatWrapping;
    //     texture.wrapT = THREE.RepeatWrapping;
    //     texture.needsUpdate = true;
    //     texture.flipY = false;
    //     texture.encoding = THREE.sRGBEncoding;
    //     texture.anisotropy = 16;
    //     texture.mapping = THREE.ACESFilmicToneMapping;
    //     var geometry = new THREE.SphereGeometry(2, 64, 32, 0, Math.PI * 2, 0, Math.PI * 2);
    
    //     var material = new THREE.MeshBasicMaterial( { map: texture,fog:false,color:0xFFFFFF} );
        
    //     var mesh = new THREE.Mesh( geometry, material );
    //     scene.add( mesh ); 
    // } );
    loader.load('https://storage.googleapis.com/grubox-store/gret/local/avatar/dog/Untitled_design_1.png', function ( texture ) {
        // console.log(texture)
        // texture.repeat.set(2,4);
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;
        texture.needsUpdate = true;
        texture.flipY = false;
        texture.encoding = THREE.sRGBEncoding;
        texture.anisotropy = 16;
        texture.mapping = THREE.ACESFilmicToneMapping;
        
        scene.background= texture;
        
    
    });
    
    const AmbientLight =new THREE.AmbientLight(0xFFFFFF,1)
    scene.add(AmbientLight)
    const PointLight = new THREE.PointLight(0xFFFFFF,1)
    PointLight.position.set(0,0,0);
    scene.add(PointLight)
    for ( let i = 0; i < 100; i ++ ) {
      const geometry = new THREE.SphereGeometry(Math.random()/100, 50, 32, 0, Math.PI * 2, 0, Math.PI * 2);
      const material = new THREE.MeshPhongMaterial({color:getDarkColor(),reflectivity:0.8,shininess: 400,});
      const object = new THREE.Mesh( geometry, material );
      object.position.x = Math.random()-0.5 
      object.position.y = Math.random()-0.5 
      object.position.z = Math.random()-0.5 
      object.rotation.x = Math.random() * 2 * Math.PI;
      object.rotation.y = Math.random() * 2 * Math.PI;
      object.rotation.z = Math.random() * 2 * Math.PI;
      const PointLight = new THREE.PointLight(0xFFFFFF,1)
      object.add(PointLight)
      scene.add( object );   
      spheres.push( object );      
		}

    generateGalaxy()
    // var geometry = new THREE.SphereGeometry(3, 50, 50, 0, Math.PI * 2, 0, Math.PI * 2);
    // var material = new THREE.MeshNormalMaterial();
    // cube = new THREE.Mesh(geometry, material);
    // scene.add(cube);
    
    camera.position.z = 0.5;
}
const parameters = {};
    parameters.count = 100000;
    parameters.size = 0.001;
    parameters.radius = 1;
    parameters.branches = 3;
    parameters.spin = 3;
    parameters.randomness = 0.2;
    parameters.randomnessPower = 3;
    parameters.insideColor = "#ed124f";
    parameters.outsideColor = "#025fdd";
    const generateGalaxy = () => {
      // Destroy old galaxy
      // Geometry
      const geometry = new THREE.BufferGeometry();
      const positions = new Float32Array(parameters.count * 3);
    
      const colors = new Float32Array(parameters.count * 3);
      const colorInside = new THREE.Color(parameters.insideColor);
      const colorOutside = new THREE.Color(parameters.outsideColor);
    
      for (let i = 0; i < parameters.count; i++) {
        const i3 = i * 3;
        const radius = Math.random() * parameters.radius;
        const spinAngle = radius * parameters.spin;
        const branchAngle =
          ((i % parameters.branches) / parameters.branches) * Math.PI * 2;
    
        const randomX =
          Math.pow(Math.random(), parameters.randomnessPower) *
          (Math.random() < 0.5 ? 1 : -1) *
          parameters.randomness *
          radius;
        const randomY =
          Math.pow(Math.random(), parameters.randomnessPower) *
          (Math.random() < 0.5 ? 1 : -1) *
          parameters.randomness *
          radius;
        const randomZ =
          Math.pow(Math.random(), parameters.randomnessPower) *
          (Math.random() < 0.5 ? 1 : -1) *
          parameters.randomness *
          radius;
    
        positions[i3] = Math.cos(branchAngle + spinAngle) * radius + randomX;
        positions[i3 + 1] = randomY;
        positions[i3 + 2] = Math.sin(branchAngle + spinAngle) * radius + randomZ;
    
        const mixedColor = colorInside.clone();
        mixedColor.lerp(colorOutside, radius / parameters.radius);
        colors[i3] = mixedColor.r;
        colors[i3 + 1] = mixedColor.g;
        colors[i3 + 2] = mixedColor.b;
      }
      geometry.setAttribute("position", new THREE.BufferAttribute(positions, 3));
      geometry.setAttribute("color", new THREE.BufferAttribute(colors, 3));
    
      // Material
      const material = new THREE.PointsMaterial({
        size: parameters.size,
        sizeAttenuation: true,
        depthWrite: false,
        blending: THREE.AdditiveBlending,
        vertexColors: true,
      });
    
      // Points
      const points = new THREE.Points(geometry, material);
      scene.add(points);
    };
function getDarkColor() {
  var color = '#';
  for (var i = 0; i < 6; i++) {
      color += Math.floor(Math.random() * (3 - 0) + 0);
  }
  return color;
}

// function onMouseWheel( event ) {
//   camera.position.z += event.deltaY * 0.1; // move camera along z-axis
// }

var render = function () {
    // orbitControls.update();
    requestAnimationFrame(render);
    const timer = 0.0001 * Date.now();

  
    // cube.rotation.y += 0.01;
    for ( let i = 0, il = spheres.length; i < il; i ++ ) {

      const sphere = spheres[ i ];

      sphere.position.x = Math.cos( timer + i );
      sphere.position.y = Math.sin( timer + i * 1.1 );

    }
    renderer.render(scene, camera);
};

const BackgroundSceneWelcome = () => {
    
    useEffect(()=>{
        container = document.getElementById('container3D')
        createSphere();
        render();
    },[])
    useEffect(()=>{
        window.addEventListener('resize', () => {
            // Update camera aspect
            camera.aspect = window.innerWidth / window.innerHeight
                  
            // Update camera projection matrix
            camera.updateProjectionMatrix()
                  
            // Resize renderer
            renderer.setSize(window.innerWidth, window.innerHeight)
          })
    })
  return (
    <div>
      <div id='container3D'></div>
      
      
    </div>
  )
}

export default BackgroundSceneWelcome
