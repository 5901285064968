import React from 'react'
import * as colors from '../../Styles/Colors';
import '../../Styles/global.css'
import '../../Styles/Planets.css'
import BackgroundScene from './BackgroundScene';


const Stories = () => {
  const array=[0,1,2,3,4,5,6]
  return (
    <div>
      {/* <div id="planet1"></div> */}
      {/* <div className="sketchfab-embed-wrapper"> 
        <iframe height={"100vh"} width={"100%"} title="Fantasy Sky Background" frameBorder="0" allowFullScreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking="true" execution-while-out-of-viewport="true" execution-while-not-rendered="true" web-share="true" src="https://sketchfab.com/models/15c79bb2fc1147128039fe4ff90fd5a0/embed?ui_theme=dark"> </iframe> 
        <p style={{fontSize: "13px", fontWeight: "normal", margin: "5px", color: "#4A4A4A"}}> 
        </p>
        </div> */}
        <div className='w3-container w3-center ' style={{position:"absolute",color:colors.off_white_1,width:'100%'}}>
          <h1 style={{margin:0}}>Stories Gym</h1>
          <p style={{margin:0}}>Lets see what is everyone creating</p>

         
        </div>
        <BackgroundScene/>
        <div className='w3-card displayGrid w3-round-large' style={{backgroundColor:colors.off_white_1,opacity:0.7}}>

        {array.map((item)=>(
          <div key={item} style={{padding:'2%'}}>
        <div className="w3-row " >

          <div className=" w3-third">
            <div className="w3-col l11 s12" >
              <img src="./Media/6.png" className='borderImage' style={{width:"100%",objectFit:'cover',}}/>
            </div>
          </div>
          
          <div className="w3-twothird bordertext" style={{color:colors.off_white_1,backgroundColor:colors.dark_blue_2,paddingTop:'2%',}}> 
            <div className="w3-row w3-container">
              <div className="w3-col s2 l2" >
                <img src="./Media/Gret.png" alt="Avatar" className="w3-circle w3-right" style={{width:"100%"}}/>
              </div>
              <div className="w3-col s4 l4">
                <p style={{paddingLeft:5}}>Lisa</p>
              </div> 
              <div className="w3-col s1 l1">
                <p></p>
              </div>
              <div className="w3-col s1 l1">
                <p style={{margin:0}}><i className="fa fa-share-alt" style={{fontSize:"25px"}}></i>4.5k</p>
              </div>
              <div className="w3-col s1 l1">
                <p></p>
              </div>
              <div className="w3-col s1 l1">
                <p style={{margin:0}}><i className="fa fa-comments" style={{fontSize:"25px"}}></i>4.5k</p>
              </div>
              <div className="w3-col s1 l1">
                <p></p>
              </div>
              <div className="w3-col s1 l1">
               <p style={{margin:0}}><i className="fa fa-heart" style={{fontSize:"25px"}}></i>2.4k</p> 
              </div>
            </div>
            <div className="w3-row w3-container">
              <p>Hey I created a scene at BuildVr. Explore and enjoy my scene. Do share and like my Scene</p>
            </div>
            
            
        </div>
          </div>
<br></br>
        </div>
        
        ))}
        
        
      </div>
      <div className='w3-card w3-round-large' style={{backgroundColor:colors.off_white_1,opacity:0.8}}>
        
        {window.innerWidth<600 && 
          <div style={{width:'100%',bottom:0,position:'absolute'}}>
            <div className="w3-row w3-center" style={{backgroundColor:colors.dark_blue_2,color:colors.off_white_1}}>
              <div className="w3-col l5 s5" >
                <h3>Points</h3>
              </div>
              <div className="w3-col l2 s2" >
                <h3>+</h3>
              </div>
              <div className="w3-col l5 s5" >
                <h3>Creations</h3>
              </div>
            </div>

          </div>
        }
      </div>
    </div>
  )
}

export default Stories
// const videoMaterial = new THREE.MeshBasicMaterial({ map: videoTexture, side: THREE.FrontSide, toneMapped: false, });
