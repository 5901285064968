import React, { useEffect, useState } from 'react'
import * as colors from '../../Styles/Colors';
import '../../Styles/global.css'
import * as media from '../../Media/Media'
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { Oval } from  'react-loader-spinner'
import { get_dashboard_kpis } from '../../Services/DashboardService'
import SideOptions from '../SideOptions';
import LoadingPage from '../LoadingPage';

const DashbordBuildVR = () => {

  const navigate=useNavigate();

  const [clientInfo,SetClientInfo]=useState()
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');

  function closeLoadingModal(event) {
    event.preventDefault()
    setLoadingModal(false);
  }
  useEffect(()=>{
    if(!localStorage.getItem('is_superuser')){
      navigate('/')
    }
    setLoadingModal(true)
    setLoadingMessage('Loading Content...')
    get_dashboard_kpis().then(result=>{
      console.log(result)
      if(result.success===true)
      {
        SetClientInfo(result)
        setLoadingModal(false)
      }
      else{
        setLoadingMessage(result.error)
        localStorage.removeItem('token')
        navigate('/')
      }
    })
  },[])
  
  
  return (
    <div>
        <div className='dashboard_layout w3-center' style={{backgroundColor:'white'}}>
          <div>
        <SideOptions props={'profile'}/>    

          </div>
        <div style={{color:colors.dark_blue_2, paddingBottom: "15%"}}>
          <div style={{marginLeft:'7%',marginRight:'7%'}}>
            <h1><b>Dashboard BuildVR</b></h1>
            <p style={{textAlign:'left'}}>Welcome {clientInfo && "Gretxp"} ! Please review your performance.</p>
            <div className='dashboard_cards' >
              <div style={{textAlign:'left'}}>
                <div style={{marginLeft:'10%'}}>
                  <br></br>
                  <span><img src={media.user_icon} alt="Image" className='' style={{width:'10%'}}/>&nbsp;&nbsp;
                  Views</span>
                  <h1>{clientInfo ?clientInfo.view:0}</h1>
                </div>
              </div>
              <div style={{textAlign:'left'}}>
                <div style={{marginLeft:'10%'}}>
                  <br></br>
                  <span><img src={media.new_reg_icon} alt="Image" className='' style={{width:'10%'}}/>&nbsp;&nbsp;
                  New registrations</span>
                  <div className='w3-col l6 s6 w3-center'>
                    <h1 style={{margin:0}}>BuildVR</h1>
                    <h1 style={{margin:0}}>{clientInfo ? clientInfo.total_registration-clientInfo.game_registerations:0}</h1>
                  </div>
                  <div className='w3-col l6 s6 w3-center'>
                    <h1 style={{margin:0}}>Create</h1>
                    <h1 style={{margin:0}}>{clientInfo ? clientInfo.game_registerations:0}</h1>
                  </div>
                </div>
              </div>
              <div style={{textAlign:'left'}}>
                <div style={{marginLeft:'10%'}}>
                  <br></br>
                  <span><img src={media.quest_icon} alt="Image" className='' style={{width:'10%'}}/>&nbsp;&nbsp;
                  Published Pages</span>
                  <h1>{clientInfo ? clientInfo.published:0}</h1>
                </div>
              </div>
            </div>
            <div className='choose_quest_layout'>
              <div>
                <div style={{display:'grid',gridTemplateColumns:'50% 50%'}}>
                <img src={"./Media/25.png"} alt="Image" className="dashboard_icons_1"/>
                <h1 className="dashboard_numbers">{clientInfo ? clientInfo.environ[0].Environment:0}</h1>
                </div>
                <p>3D Environments</p>
              </div>
              <div>
              <div style={{display:'grid',gridTemplateColumns:'50% 50%'}}>
                <img src={"./Media/26.png"} alt="Image" className="dashboard_icons_1"/>
                <h1 className="dashboard_numbers">{clientInfo ? clientInfo.environ[1].Props:0}</h1>
                </div>
                <p>3D Props </p>

              </div>
              <div>
              <div style={{display:'grid',gridTemplateColumns:'50% 50%'}}>
                <img src={"./Media/27.png"} alt="Image" className="dashboard_icons_1"/>
                <h1 className="dashboard_numbers">{clientInfo ? clientInfo.environ[2].Avatar:0}</h1>
                </div>
                <p>Avatars</p>

              </div>
              <div>
              <div style={{display:'grid',gridTemplateColumns:'50% 50%'}}>
                <img src={"./Media/28.png"} alt="Image" className="dashboard_icons_1"/>
                <h1 className="dashboard_numbers">{clientInfo ? clientInfo.environ[3].Video+clientInfo.environ[4]["Background image"]:0}</h1>
                </div>
                <p>Images & Videos Uploaded</p>
              </div>
            </div>
          </div>
        </div> 
        <div style={{backgroundColor:colors.dark_blue_2,height:'100vh'}}>
          <div style={{display:'grid',gridTemplateRows:'90% 10%',marginTop:'30%'}}>
            {/* <img src="./Media/14.png" style={{width:'70%',marginLeft:'15%'}}/> */}
            <img src="./Media/15.png" style={{width:'100%',}}/>
            <a className='signup_button' href='https://buildvr.gretxp.com' style={{backgroundColor:colors.off_white_1,color:colors.dark_blue_2,width:'70%',marginLeft:'15%'}} target = "_blank" rel = "noopener noreferrer">Create WebVR</a>
          </div>
        </div>      
        </div>
        <LoadingPage loadingMessage={loadingMessage} loadingModal={loadingModal}/>
    </div>
  )
}

export default DashbordBuildVR
