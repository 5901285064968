import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import * as colors from '../../Styles/Colors';
import '../../Styles/global.css'
import { useNavigate } from 'react-router-dom';
import { facebook_login, google_login, verify_activation_code } from '../../Services/LoginService';
import { send_activation_code } from '../../Services/ClientServices';
import useGAEventTracker from '../useGAEventTracker';
import { GoogleLogin } from '@react-oauth/google';
import LoadingPage from '../LoadingPage';
import { GoogleIcon, login_image } from '../../Media/Media';
import { useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
import { get_google_info } from '../../Services/GoogleServices';


const LoginPage = () => {
  const GAEventsTracker = useGAEventTracker("Login");
  const navigate = useNavigate();
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [error, setError] = useState('');
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [check_box, setCheck_box] = useState(false)
  const [token, setToken] = useState(null)
  const [method, setMethod] = useState()
  const [disabled, setDisabled] = useState(true)
  const [stage, setStage] = useState(1)

  const responseGoogle = response => {
    setLoadingModal(true)
    // console.log(response)
    google_login(response.credential).then(result => {
      if (result.token !== null) {
        setLoadingModal(false)
        setLoadingMessage('')
        navigate('/dashboard')
      }
      else {
        setLoadingModal(true)
        setLoadingMessage(result.error)
        navigate('/login')
      }
    })
  };

  const handleOnChangeCheckBox = (event) => {
    setCheck_box(!check_box);
    // setMessage('')
  }

  const handleOnChangeEmail = (event) => {
    setEmail(event.target.value);
  }

  const handleOnChangePassword = (event) => {
    setPassword(event.target.value);

  }

  function closeLoadingModal(event) {
    event.preventDefault()
    setLoadingModal(false);
  }
  useEffect(() => {
    if (password !== '' && email !== '') {
      setDisabled(false)
    }
    setToken(localStorage.getItem('token'))
    if (localStorage.getItem('token') !== null && localStorage.getItem('token') !== undefined) {
      navigate("/dashboard");
    }
  })

  const methodAction = () => {
    setLoadingModal(true)
    setStage(2);
    setLoadingMessage('Sending OTP on your email...')
    GAEventsTracker('/login');
    send_activation_code(email).then(result => {
      if (result.success === true) {
        setLoadingMessage('')
        setError('')
        setLoadingModal(false)
      }
      else {
        setError(result.error)
        setLoadingModal(false)
      }
    }).catch(() => {
      setError('Something went wrong! Please try again later')
    })
  }

  function verifyActivationCode(e, type) {
    e.preventDefault();
    setLoadingModal(true)
    setLoadingMessage('Loading Content...')
    verify_activation_code(email, password, type).then(result => {
      if (result.success === true) {
        setLoadingModal(false)
        setLoadingMessage('')
        setError('')
        navigate('/dashboard')
      }
      else {
        setLoadingModal(false)
        setError(result.error)
      }
    }).catch(() => {
      setError('Something went wrong! Please try again later')
    })
  }

  function google_login_button(tokenResponse) {
    setLoadingModal(true)
    get_google_info(tokenResponse).then(result => {
      var credentials = {
        "email": result.email,
        "name": result.email.split("@")[0],
        "user_image": null,
        "game_user": true,
        "vr_user": false
      }
      facebook_login(credentials).then(result => {
        if (result.token !== null) {
          setLoadingModal(false)
          setLoadingMessage('')
          setError('')
          navigate('/dashboard')
        }
        else {
          setLoadingModal(true)
          setError(result.error)
        }
      }).catch(() => {
        setError('Something went wrong! Please try again later')
      })
    }).catch(() => {
      setError('Something went wrong! Please try again later')
    })
  }
  const login = useGoogleLogin({
    onSuccess: tokenResponse => google_login_button(tokenResponse),
    onError: tokenResponse => google_login_button(tokenResponse)
  });

  useGoogleOneTapLogin({
    onSuccess: tokenResponse => responseGoogle(tokenResponse),
    onError: tokenResponse => responseGoogle(tokenResponse)
  });

  return (
    <>
      <div className="w3-row">
        <div className="w3-half w3-center center-item landing-section" style={{ backgroundColor: colors.color_1, color: colors.color_3, height: '100vh' }}>
          <div className='w3-col'>
            <h1 className="right_section1"><b>Welcome!</b></h1>
            <p className="right_section1">
              Don't have an account yet? <Link to="/signup">Sign Up</Link>
            </p>
            {error != '' && <h3>Error! {error}</h3>}

            <div className='w3-row login_margin w3-left-align'>
              <label className='w3-col'>
                Email
                <input className='outline-input' style={{ width: '100%' }} onChange={handleOnChangeEmail} type="text" name="username" />
              </label>
            </div>

            {/* <button onClick={sendActivationCode} className='link_button' style={{paddingRight:'77%',color:colors.dark_green_1,marginLeft:'-7%'}}>Send OTP</button> */}
            {stage === 2 && method === true &&
              <div className='login_margin w3-left-align'>
                <p>
                  OTP <small>(Otp Sent on your email)</small>
                  <input className='outline-input' style={{ width: '100%' }} onChange={handleOnChangePassword} type="number" name="username" />
                </p>
              </div>
            }
            {stage === 2 && method === false &&
              <div className='login_margin w3-left-align'>
                <p>
                  Password
                  <input className='outline-input' style={{ width: '100%' }} onChange={handleOnChangePassword} type="password" name="username" />
                </p>
              </div>
            }

            {stage === 1 &&
              <>
                <div className='login_margin center-item' style={{ gap: 16 }}>
                  <button className="button-dark w3-col" onClick={() => { setMethod(true); methodAction() }} disabled={email === ''}> Login using OTP</button>
                  <button className="button-dark w3-col" onClick={() => { setMethod(false); setStage(2) }} disabled={email === ''}> Login using Password</button>
                  <button className="button-dark w3-col" onClick={() => login()}>
                    <div className='center-item-row'>
                      <img src={GoogleIcon} width={25} />
                      <p className='w3-col l11 s11 no-margin' style={{ fontSize: 16 }}>Login with Google</p>
                    </div>
                  </button>
                </div>
              </>
            }

            {stage === 2 && method === true &&
              <div className='login_margin center-item'>
                <button className="button-dark w3-col" onClick={(e) => verifyActivationCode(e, 'OTP')}  disabled={disabled} >
                  Login
                </button>
              </div>
            }
            {stage === 2 && method === false &&
              <div className='login_margin center-item'>
                <button className="button-dark w3-col" onClick={(e) => verifyActivationCode(e, 'password')}  disabled={disabled} >
                  Login
                </button>
              </div>
            }
          </div>
        </div>
        <div className='w3-half'>
          <img src={login_image} style={{ height: '100vh', objectFit: 'cover' }} className='w3-col' />
        </div>
        <LoadingPage loadingMessage={loadingMessage} loadingModal={loadingModal} />

      </div>

    </>
  )
}

export default LoginPage
