import React, { useState,useEffect } from 'react'
import { get_all_experiences } from '../../../Services/ModelService';
import * as colors from '../../../Styles/Colors';
import './profilepages.css'
import Modal from 'react-modal';
import { Oval } from  'react-loader-spinner'
import { useNavigate } from 'react-router-dom';
import SideOptions from '../../SideOptions';

const SelfProfile = () => {
  Modal.setAppElement('#root');

  const navigate=useNavigate()
  const [VrExpCollection,setVrExpCollection]=useState(null)
  const [token,setToken]=useState(null)
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [activeCategory,setActiveCategory ]=useState(true)
  
  useEffect(()=>{       
    if(localStorage.getItem("token")!==null){
        setToken(localStorage.getItem("token"))
        setLoadingModal(true)
        setLoadingMessage("Loading Content...")
        get_all_experiences().then(result => {
            if(result.success){
                setVrExpCollection(result.experience)
                setLoadingModal(false)
                setLoadingMessage("")
            }
            else{
                setLoadingModal(false)
                localStorage.clear();
                setToken(null)
            }   
          })
      }
      else{
        navigate('/')
      }
  },[])

  function cancelBubble(e){
    e.cancelBubble = true;
    if(e.stopPropagation)
    e.stopPropagation();
  }
  function openScene(id){
    var url=`https://buildvr.gretxp.com/publish?experience_id=${id}`
    window.open(url,"_blank")
  }
  function editScene(id){
    var url=`https://buildvr.gretxp.com/scene?experience_id=${id}`
    window.open(url,"_blank")
  }
  return (
    <div>
        <div style={{backgroundColor:colors.off_white_1,color:colors.dark_blue_2,height:'auto',minHeight:'100vh'}}>
        {token && <SideOptions props={'profile'}/>} 
          <div style={{display:'flex'}}>
            <img src='./Media/Gret.png' className='gretLogo'/>
            <h2 style={{fontWeight:'bolder',marginLeft:5,}}>Gretxp</h2> 
          </div>
          <div className="w3-row">
            <div className="w3-col s7 l9 w3-center">
              <h1></h1>
            </div>
            <div className="w3-col s4 l2 w3-right-align">
              <h2 >My profile</h2>
              <hr className='hrStyle' style={{borderTop:"2px solid var(--dark-blue-2)"}}/>
              <button className='signup_button w3-col s12 l12' style={{margin:'1%'}}> Add Avatar</button>
            </div>
            <div className="w3-col s1 l1 w3-right-align"></div>
          </div>
        <br></br>
          
          <div className="w3-row w3-section">
            <div className="w3-col s6 w3-center">
              <h3 style={{margin:0}}>Cahaya Dewi</h3>
              <p>Hello, I'm Cahaya Dewi.</p>
              <p>Welcome to my profile!</p>
            </div>
            <div className="w3-col s6 w3-center">
              <div className='w3-col s4 l5'><h1></h1></div>
              <img src="./Media/49.png" className='w3-col s6 l2' />
            </div>
          </div>
          <div className="w3-row w3-section sceneGrid">
            <div className="w3-col l4 s4 w3-center">
              <h3>23</h3>
              <h3>Scenes</h3>
            </div>
            <div className="w3-col l4 s4 w3-center">
              <h3>1000</h3>
              <h3>Viewers</h3>
            </div>
            <div className="w3-col l4 s4 w3-center">
              <h3>150</h3>
              <h3>Creat Points</h3>
            </div>
          </div>
          <div className="w3-row w3-section">
            <div className="w3-col l3  w3-center"><h1></h1></div>
            <div className="w3-col  l3 s6 w3-center">
              <div onClick={()=>setActiveCategory(true)} style={{borderBottom: `5px solid ${activeCategory?colors.dark_blue_2:'#ccc'}`, color:activeCategory?colors.dark_blue_2:'#ccc',cursor:'pointer'}}>
                <h3>Scenes</h3>
              </div>
            </div>
            <div className="w3-col l3 s6 w3-center">
              <div onClick={()=>setActiveCategory(false)} style={{borderBottom: `5px solid ${!activeCategory?colors.dark_blue_2:'#ccc'}`, color:!activeCategory?colors.dark_blue_2:'#ccc',cursor:'pointer'}}>
                <h3>Favorites</h3>
              </div>
            </div>
          </div>

             {activeCategory?
             <div className='w3-row sceneGrid' style={{}}>
              <div>
                {/* <div className="w3-col l3 s6 w3-center" style={{padding:'1%'}}>
                  <div className='w3-card create_card' onClick={(e)=>{cancelBubble(e)}}>
                    <img src={item.preview?item.preview:"./Media/9.png"} alt="./Media/9.png" className='create_img' ></img>
                      <div>
                        <p style={{margin:'1%',padding:'1%',float:'left'}}>{item.name?item.name.length>10?item.name.slice(0, 10) + "...":item.name:"VR Tour"}</p>
                        <button style={{float:'right'}} className="editButton" onClick={(e)=>{editScene(item.unique_id);cancelBubble(e)}}><i className='fa fa-edit' style={{ fontSize: 24 }}></i></button>
                      </div>
                    </div>
                  </div> */}
                </div>
            {VrExpCollection && VrExpCollection.map((item)=>(
                    <div key={item.experience_id} >
                      <div className="w3-col l3 s6 w3-center" style={{padding:'1%'}}>
                        <div className='w3-card create_card' onClick={(e)=>{openScene(item.unique_id);cancelBubble(e)}}>
                            <img src={item.preview?item.preview:"./Media/9.png"} alt="./Media/9.png" className='create_img' ></img>
                            <div>
                                <p style={{margin:'1%',padding:'1%',float:'left'}}>{item.name?item.name.length>10?item.name.slice(0, 10) + "...":item.name:"VR Tour"}</p>
                                <button style={{float:'right'}} className="editButton" onClick={(e)=>{editScene(item.unique_id);cancelBubble(e)}}><i className='fa fa-edit' style={{ fontSize: 24 }}></i></button>
                            </div>
                        </div>
                      </div>
                    </div>
            ))}
            </div>
             :
             <>
             </>
             }     

        </div>
        {loadingModal && 
        <Modal
        isOpen={loadingModal}
        onRequestClose={()=>setLoadingModal(false)}
        className='loading_modal'
        contentLabel="Example Modal"
        >
          <div className="w3-container " style={{height:"33%"}}>
            <p></p>
          </div>

          <div className="w3-container  w3-center" style={{height:"33%"}}>
            <div className='w3-col l6 s4 w3-center'><h1></h1></div>
            <div className='w3-col l6 s8 w3-center'>
              <Oval color='var(--dark-blue-2)' height={90} width={90} secondaryColor="var(--off-white-1)" />
            </div>
            <p style={{color:colors.dark_blue_2,textAlign:'center'}}>{loadingMessage}</p>
            
          </div>
          <div className="w3-container" style={{height:"33%"}}>
            <p></p>
          </div>
          {/* <div className='loadingPosition'>
            <div  className=' loading_modal_content'>
                <div className='loadingOval'>
                    <Oval color='var(--dark-blue-2)' height={80} width={80} secondaryColor="var(--off-white-1)" />
                </div>
                <br></br>
                <p style={{color:colors.dark_blue_2,textAlign:'center'}}>{loadingMessage}</p>
            </div>
        </div> */}
      </Modal>
      }
      
    </div>
  )
}

export default SelfProfile

