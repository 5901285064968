import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { send_activation_code, register_client } from '../../Services/ClientServices';
import * as colors from '../../Styles/Colors';
import '../../Styles/global.css'
import { facebook_login, google_login, verify_activation_code } from '../../Services/LoginService';
import LoadingPage from '../LoadingPage';
import { GoogleIcon, login_image } from '../../Media/Media';
import { useGoogleLogin } from '@react-oauth/google';
import { get_google_info } from '../../Services/GoogleServices';

const NewSignup = () => {

  const [email, setEmail] = useState('')
  const [brandName, setBrandName] = useState('')
  const [website, setWebsite] = useState('')
  const [otp, setOTP] = useState('')
  const [select, setSelect] = useState("")
  const [check_box, setCheck_box] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [message, setMessage] = useState('')
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  const [stage, setStage] = useState(1);
  const [token, setToken] = useState(null)

  const navigate = useNavigate()


  function closeLoadingModal(event) {
    event.preventDefault()
    setLoadingModal(false);
  }

  const handleOnChangeSelect = (event) => {
    setSelect(event.target.value);
  }
  const handleOnChangeCheckBox = (event) => {
    setCheck_box(!check_box);
  }
  useEffect(() => {
    if (password !== '' && email !== '') {
      setDisabled(false)
    }
    setToken(localStorage.getItem('token'))
    if (token !== null && token !== undefined) {
      if (localStorage.getItem("loginType")) {
        navigate('/design')
      }
      else {
        navigate("/dashboard");
      }
    }
  })
  useEffect(() => {
    if (email !== '' && otp !== '' && check_box === true) {
      setDisabled(false)
    }
    else if (stage == 2 && otp !== '') {
      setDisabled(false)
    }
    else if (email && stage === 1) {
      setDisabled(false)
    }
    else if (password !== '' && check_box === true && stage == 3) {
      setDisabled(false)
    }
    else {
      setDisabled(true)
    }

  });

  const sendActivationCode = (event) => {
    event.preventDefault();
    setLoadingModal(true)
    setLoadingMessage('Sending OTP on your email...')
    send_activation_code(email).then(result => {
      if (result.success === true) {
        setLoadingMessage('')
        setLoadingModal(false)
        setStage(2);
        setCheck_box(false)
      }
      else {
        setLoadingMessage('')
        setLoadingModal(false)
      }
    })
  }
  const verifyDetails = (event) => {
    if (stage === 2) {
      setStage(3);
    }
    else if (stage === 3) {
      if (disabled === false) {
        const client = {
          "email": email,
          "name": brandName,
          "website": website,
          "otp": otp,
          "password": password,
          "target": select,
          "tnc": true
        }
        setLoadingModal(true)
        setLoadingMessage('Loading Content...')
        register_client(client).then(result => {
          if (result.success === true) {
            if (localStorage.getItem("loginType")) {
              navigate('/design')
            }
            else {
              navigate('/gamification')
            }
            setLoadingModal(false)
            setLoadingMessage('')
          }
          else {
            setLoadingModal(true)
            setLoadingMessage(result.error)
            // navigate('/login')
          }
        })
      }
    }
    event.preventDefault();
  }
  const responseGoogle = response => {
    // console.log(response)
    setLoadingModal(true)
    google_login(response.credential).then(result => {
      if (result.token !== null) {
        setLoadingModal(false)
        setLoadingMessage('')
        navigate('/gamification')
      }
      else {
        setLoadingModal(true)
        setLoadingMessage(result.error)
        navigate('/login')
      }
    })
  };

  function google_login_button(tokenResponse) {
    setLoadingModal(true)
    get_google_info(tokenResponse).then(result => {
      var credentials = {
        "email": result.email,
        "name": result.email.split("@")[0],
        "user_image": null,
        "game_user": true,
        "vr_user": false
      }
      facebook_login(credentials).then(result => {
        if (result.token !== null) {
          setLoadingModal(false)
          setLoadingMessage('')
          navigate('/gamification')
        }
        else {
          setLoadingModal(true)
          setLoadingMessage(result.error)
          navigate('/login')
        }
      })
    })
  }
  const login = useGoogleLogin({
    onSuccess: tokenResponse => google_login_button(tokenResponse),
    onError: tokenResponse => google_login_button(tokenResponse)
  });
  return (
    <div>
      <div className="w3-half center-item landing-section" style={{ backgroundColor: colors.color_1, color: colors.color_3, height: '100vh' }}>
        <div className='w3-col space-item login_margin'>
          <h1 className="w3-left-align" style={{ fontWeight: 'bolder' }} >Signup</h1>
          <p style={{ margin: 0 }} className="w3-center">Already Registered? <Link to='/login' className="btn" > Log in here.</Link>
          </p>
        </div>
        <div className='w3-col login_margin'>
          <h3>Create an account</h3>
          <p className='no-margin'>Let's get started!</p>
          {error != '' && <h3>Error! {error}</h3>}
        </div>
        <div className='w3-col login_margin w3-section'>
          <>
            <label style={{ textAlign: 'left' }}>
              Email
              <input className='outline-input' style={{ width: '100%' }} onChange={(e) => setEmail(e.target.value)} type="text" name="username" />
            </label>
          </>
          {stage === 1 &&
            <>
              {/* <label>
                <input type='checkbox' onClick={handleOnChangeCheckBox} className='signup_checkbox ' />
                <small>By continuing, you agree with our <a href='Term & Conditions'>Term & Conditions</a> and <a href='https://www.gretxp.com/privacy'>Privacy Policy</a>.</small>
              </label> */}
              <div className='center-item w3-section' style={{ gap: 16 }}>
                <button className="button-dark w3-col" onClick={sendActivationCode} style={{ backgroundColor: `${disabled ? '#ccc' : colors.color_2}` }} disabled={disabled}>
                  Send OTP
                </button>
                <button className="button-dark w3-col" onClick={() => login()}>
                  <div className='center-item-row'>
                    <img src={GoogleIcon} width={25} />
                    <p className='w3-col l11 s11 no-margin' style={{ fontSize: 16 }}>Login with Google</p>
                  </div>
                </button>
              </div>
            </>
          }

          {stage === 2 &&
            <>
              <p style={{ margin: 0 }}>We have shared an OTP at your email</p>
              <div className=''>
                <div className="w3-row">
                  <label style={{ textAlign: 'left' }}>OTP
                    <input onChange={(e) => setOTP(e.target.value)} className='outline-input' style={{ width: '100%' }} type="number" name="name" />
                  </label>
                </div>
                <br/>
                <button className="button-dark w3-col" onClick={verifyDetails} style={{ backgroundColor: `${disabled ? '#ccc' : colors.color_2}` }} disabled={disabled}>
                  Sign up
                </button>
              </div>
            </>
          }

          {stage === 3 &&
            <>
              <div >
                <form onSubmit={verifyDetails}>
                  <div className="w3-row">
                    <div className="w3-col" style={{ paddingTop: 8 }}>
                      <p style={{ textAlign: 'left', margin: 0 }}>Password*<br></br>
                        <input onChange={(e) => { setPassword(e.target.value) }} className='outline-input' style={{ width: '100%' }} type="password" name="name" />
                      </p>
                    </div>
                    <div className="w3-col" style={{ paddingTop: 8 }}>
                      <p style={{ textAlign: 'left', margin: 0 }}> Brand Name<br></br>
                        <input onChange={(e) => setBrandName(e.target.value)} className='outline-input' style={{ width: '100%' }} type="text" name="name" />
                      </p>
                    </div>
                    <div className="w3-col" style={{ paddingTop: 8 }}>
                      <p style={{ textAlign: 'left', margin: 0 }}>Website<br></br>
                        <input onChange={(e) => setWebsite(e.target.value)} className='outline-input' style={{ width: '100%' }} type="text" name="name" />
                      </p>
                    </div>
                    <div className="w3-col" style={{ paddingTop: 8 }}>
                      <p style={{ textAlign: 'left', margin: 0 }}>Requirement<br></br>
                        <select defaultValue="increase_engagement" onChange={handleOnChangeSelect} style={{ width: '100%' }} className='outline-input' >
                          <option value="Increase sales at Website">Increase sales at Website</option>
                          <option value="Increase registered subscribers">Increase registered subscribers</option>
                          <option value="Increase user engagement with your website">Increase user engagement with your website</option>
                        </select>
                      </p>
                    </div>
                    <div className='w3-col w3-left-align' style={{ paddingTop: 8 }}>
                      <label>
                        <input type='checkbox' value={check_box} onClick={handleOnChangeCheckBox} className='signup_checkbox ' />
                        By continuing, you agree with our <a href='Term & Conditions'>Term & Conditions</a> and <a href='https://www.gretxp.com/privacy'>Privacy Policy</a>.
                      </label>
                    </div>
                  </div>
                  <input className='button-dark w3-col' style={disabled ? { backgroundColor: '#ccc', paddingTop: 8 } : { paddingTop: 8, }} disabled={disabled} type="submit" value="Finish" />
                </form>
              </div>
            </>
          }
        </div>

      </div>
      <div className='w3-half'>
        <img src={login_image} style={{ height: '100vh', objectFit: 'cover' }} className='w3-col' />
      </div>



      <LoadingPage loadingMessage={loadingMessage} loadingModal={loadingModal} />

    </div>
  )
}

export default NewSignup
