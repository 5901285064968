import React from 'react'
import Modal from 'react-modal';
import { Oval } from  'react-loader-spinner'
import * as colors from '../Styles/Colors';
import '../Styles/global.css'

const LoadingPage = (props) => {
    function closeModal(){

    }
    Modal.setAppElement('#root');
  return (
    <div>
      <Modal
        isOpen={props.loadingModal}
        onRequestClose={closeModal}
        className='loading_modal'
        contentLabel="Example Modal"
        >
                <div style={{position: 'absolute', 
                  top: 0, left: 0, 
                  right: 0, bottom: 0, 
                  justifyContent: 'center', 
                  alignItems: 'center',
                  display:'flex'}}>
                    <div>
                    <div style={{ 
                      top: 0, left: 0, 
                      right: 0, bottom: 0, 
                      justifyContent: 'center', 
                      alignItems: 'center',
                      display:'flex'}}>
                      <Oval color='var(--dark-blue-2)' height={100} width={100} secondaryColor="var(--off-white-1)" />
                      
                      </div>
                      <p style={{color:colors.dark_blue_2,textAlign:'center'}}>{props.loadingMessage}</p>
                    </div>   
                </div>            
      </Modal>
    </div>
  )
}

export default LoadingPage
