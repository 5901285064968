import environment from "../Environments/Environmnet";
import React from "react";
import { get, set } from "../LocalStorage/LocalStorage";

export const send_activation_code = async (email) => {
    let user_email = { email };
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: user_email.email, game_user: true })
    };
    const response = await fetch(`${environment.server_root}/api/profile/send_activation_code/`, requestOptions);
    const data = await response.json();
    return data
}
export const verify_activation_code = async (email, password, type) => {
    if (type === "password") {
        let user_email = { email };
        let user_code = { password };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: user_email.email, password: user_code.password, game_user: true,app_name:"Creator"  })
        };
        const response = await fetch(`${environment.server_root}/api/profile/get_user_token/`, requestOptions);
        const new_data = await response.json();
        if (new_data.success === true) {
            if (new_data.data.token != null) {
                const token = new_data.data.token;
                localStorage.setItem('token', token)
                const temp = localStorage.getItem('token')
                localStorage.setItem('is_superuser', new_data.data.is_superuser)
            }
        }
        return new_data;
    }
    else {
        let user_email = { email };
        let user_code = { password };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: user_email.email, otp: user_code.password, game_user: true,app_name:"Creator" })
        };
        const response = await fetch(`${environment.server_root}/api/profile/get_user_token/`, requestOptions);
        const new_data = await response.json();
        if (new_data.success === true) {
            if (new_data.data.token != null) {
                const token = new_data.data.token;
                localStorage.setItem('token', token)
                localStorage.setItem('is_superuser', new_data.data.is_superuser)
            }
        }
        return new_data;
    }

}

export const google_login = async (credential) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "credential": credential, game_user: true,app_name:"Creator" })
    };
    const response = await fetch(`${environment.server_root}/api/profile/google_link/`, requestOptions);
    const data = await response.json();
    if (data.token != null) {
        const token = data.token;
        localStorage.setItem('token', token)
        localStorage.setItem('is_superuser', data.is_superuser)
    }

    return data
}

export const facebook_login = async (credential) => {
    credential['app_name']="Creator";
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(credential)
    };
    const response = await fetch(`${environment.server_root}/api/profile/facebook_login/`, requestOptions);
    const data = await response.json();
    if (data.token != null) {
        localStorage.setItem('token', data.token)
        localStorage.setItem("is_superuser", data.is_superuser)
        localStorage.setItem("experience_count", data.experience_count)
        localStorage.setItem("email", credential.email)
    }
    return data
}



